import React, { Fragment, useEffect, useState } from 'react'
import { EuiBasicTable, EuiButtonIcon, EuiCard, EuiCodeBlock, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLink, EuiSearchBar, EuiSwitch, EuiText, RIGHT_ALIGNMENT } from '@elastic/eui';
import {
    EuiButton,
    EuiDescriptionList,
    EuiHealth,     
    EuiPageContent,
    EuiPageContentBody,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiSpacer,
    EuiTitle,
  } from '@elastic/eui';
import Axios from 'axios';
import Api from '../../Api';
import AlertByRisk from '../WebScanWidgets/AlertByRisk';
import LoadingModal from '../CommonWidgets/LoadingModal';
import NotesFlyout from '../CommonWidgets/NotesFlyout';
import FilterBar from '../CommonWidgets/FilterBar';
import CompliancePdfReport from './CompliancePdfReport';
import ExportAsCSV from '../CommonWidgets/ExportAsCSV';

const DEFAULT_FILTERS = {
    Severity: "false",
    StigID        : "false",
    Host          : "false",
    SearchQuery   : "false",
    Status        : "Pending"
}

const os_class_documentation_lookup = {
    "windowsServer_2019": "https://lookerstudio.google.com/u/1/reporting/cdd183a6-9fda-4b07-9cc8-bb4cc83d3a9b/page/kUCqD",
    "Microsoft Windows 11 Pro": "https://lookerstudio.google.com/reporting/96e0e388-c8bf-4034-b2f2-a62fd018e173/page/eCCqD",
    "windows_10_21H2": "https://lookerstudio.google.com/reporting/96e0e388-c8bf-4034-b2f2-a62fd018e173/page/eCCqD",
    "windowsServer_2022": "https://lookerstudio.google.com/u/1/reporting/cdd183a6-9fda-4b07-9cc8-bb4cc83d3a9b/page/kUCqD",
    "Red Hat Enterprise Linux Server 7.8 (Maipo)": "https://lookerstudio.google.com/u/1/reporting/28b27290-7978-4b99-8ea0-611657a331ee/page/kUCqD",
    "Rocky Linux 9.2 (Blue Onyx)": "https://lookerstudio.google.com/u/1/reporting/af1a4ee1-13d5-43ed-94fa-f2f0add4e113/page/kUCqD",
    "Red Hat Enterprise Linux 8.6 (Ootpa)": "https://lookerstudio.google.com/u/1/reporting/8cc01130-1c2c-4167-99e5-ecc41d48495d/page/kUCqD",
    "redhat_7": "https://lookerstudio.google.com/u/1/reporting/28b27290-7978-4b99-8ea0-611657a331ee/page/kUCqD",
    "redhat_9": "https://lookerstudio.google.com/u/1/reporting/af1a4ee1-13d5-43ed-94fa-f2f0add4e113/page/kUCqD",
    "Microsoft Windows 10 Pro": "https://lookerstudio.google.com/reporting/96e0e388-c8bf-4034-b2f2-a62fd018e173/page/eCCqD",
    "rockylinux_9": "https://lookerstudio.google.com/u/1/reporting/af1a4ee1-13d5-43ed-94fa-f2f0add4e113/page/kUCqD",
    "ubuntu_": "https://lookerstudio.google.com/u/1/reporting/29017a88-aa4d-41a6-b602-aeca5253c1fd/page/kUCqD",
    "CentOS Linux 7 (Core)": "https://lookerstudio.google.com/u/1/reporting/28b27290-7978-4b99-8ea0-611657a331ee/page/kUCqD",
    "centos_7": "https://lookerstudio.google.com/u/1/reporting/28b27290-7978-4b99-8ea0-611657a331ee/page/kUCqD",
    "windows_11_21H2": "https://lookerstudio.google.com/reporting/96e0e388-c8bf-4034-b2f2-a62fd018e173/page/eCCqD",
    "Amazon Linux AMI 2018.03": "https://lookerstudio.google.com/u/1/reporting/28b27290-7978-4b99-8ea0-611657a331ee/page/kUCqD",
    "Red Hat Enterprise Linux 9.2 (Plow)": "https://lookerstudio.google.com/u/1/reporting/af1a4ee1-13d5-43ed-94fa-f2f0add4e113/page/kUCqD",
    "rockylinux_8": "https://lookerstudio.google.com/u/1/reporting/8cc01130-1c2c-4167-99e5-ecc41d48495d/page/kUCqD",
    "windows_11_23H2": "https://lookerstudio.google.com/reporting/96e0e388-c8bf-4034-b2f2-a62fd018e173/page/eCCqD",
    "Amazon Linux 2": "https://lookerstudio.google.com/u/1/reporting/28b27290-7978-4b99-8ea0-611657a331ee/page/kUCqD",
    "redhat_8": "https://lookerstudio.google.com/u/1/reporting/8cc01130-1c2c-4167-99e5-ecc41d48495d/page/kUCqD",
    "Rocky Linux 8.8 (Green Obsidian)": "https://lookerstudio.google.com/u/1/reporting/8cc01130-1c2c-4167-99e5-ecc41d48495d/page/kUCqD",
    "windowsServer_2016": "https://lookerstudio.google.com/u/1/reporting/cdd183a6-9fda-4b07-9cc8-bb4cc83d3a9b/page/kUCqD",
    "windows_11_22H2": "https://lookerstudio.google.com/reporting/96e0e388-c8bf-4034-b2f2-a62fd018e173/page/eCCqD",
    "windows_10_22H2": "https://lookerstudio.google.com/reporting/96e0e388-c8bf-4034-b2f2-a62fd018e173/page/eCCqD",
    "Microsoft Windows Server 2022 Standard": "https://lookerstudio.google.com/u/1/reporting/cdd183a6-9fda-4b07-9cc8-bb4cc83d3a9b/page/kUCqD",
    "osquery": "https://lookerstudio.google.com/u/1/reporting/bd45035c-7021-42ff-9a69-73c40d265047/page/eCCqD"
}

const graphTheme = {
    axis: {
        ticks: {
            text:{
                fill: "wheat"
            }
        },
        legend: {
            text: {
                fill: "wheat"
            }
        }
    },
    tooltip: {
        container: {
            background: '#333',
        },
    },
}

const ComplianceTableV2 = () => {

    const [isLoading, changeIsLoading] = useState(true)
    const [TableData, changeTableData] = useState([])    

    const [ByRiskData, changeByRiskData] = useState([])    
    const [ByStatusData, changeByStatusData] = useState([])    
    const [ByHostData, changeByHostData] = useState([])    

    const [notesFlyoutVisible, changeNotesFlyoutVisible] = useState(false)
    const [selectedNote, changeSelectedNote] = useState()
    const [selectedNoteText, changeSelectedNoteText] = useState("")    
    
    const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState({});
    const [pdfContent, changePdfContent] = useState(null)

    const [tablePageIndex, changeTablePageIndex] = useState(0)
    const [tablePageSize, changeTablePageSize] = useState(10)
    const [tableTotalSize, changeTableTotalSize] = useState(10)
    const [tableSortField, changeTableSortField] = useState('Hostname')
    const [tableSortDirection, changeTableSortDirection] = useState('desc')
    const [tableFilters, changeTableFilters] = useState(DEFAULT_FILTERS)    

    const [showOnlyPending, changeShowOnlyPending] = useState(true)

    const onTableChange = ({page, sort}) => {
        changeTablePageIndex(page.index)
        changeTablePageSize(page.size)
        changeTableSortDirection(sort.direction)
        changeTableSortField(sort.field)
    }

    const onSearchChange = (e)=>{
        handleChangeTableFilters({
            ...tableFilters,
            SearchQuery: e.queryText
        })
    }

    const handleNoteUpdate = (noteText) => {

        Axios.post(Api.submitNoteComplianceAlert, {
            ID: selectedNote,
            Note: noteText
        }).then(resp => {
            changeNotesFlyoutVisible(false)
            alert("Note Updated Successfully!")
            populateData().then( data => {
                let item = data.ComplianceAlerts.find(item => item.ID === selectedNote)
                toggleDetails(item, true)
            })
        }).catch(err => {
            alert("Could Not Update Note!")
        })
    }    

    const handleGeneratePDF = () => {

        populateData(true).then( data => {
            console.log("generate report...")
            const imagesData = document.getElementsByTagName('canvas')
            const riskImage = imagesData[0].toDataURL()
            const hostImage = imagesData[1].toDataURL()
            const statusImage = imagesData[2].toDataURL()
    
    
            changePdfContent(<CompliancePdfReport 
            
                selectedStatus={tableFilters.Status}
            
                risk={{
                    Image: riskImage,
                    Data: ByRiskData
                }}
    
                resolution={{
                    Image: statusImage,
                    Data: ByStatusData
                }}
    
                hosts={{
                    Image: hostImage,
                    Data: ByHostData
                }}
    
                hostImage
    
                alertData={data}
            />)
        })
        
    }

    useEffect(()=>{
        handleChangeTableFilters({
            ...tableFilters,
            Status: showOnlyPending ? "Pending" : "false"
        })
    },[showOnlyPending])

    const populateData = (isPDFReport = false) => {     
        changeIsLoading(true)
        if (isPDFReport){
            return Axios.post(Api.retrieveComplianceAlerts, {
                PageIndex: tablePageIndex,
                PageSize: tablePageSize,
                SortField: tableSortField,
                SortDirection: tableSortDirection,
                Filters: tableFilters
            }).then(resp => {                
    
                console.log("got report data...")
                if (resp.data && resp.data.ComplianceAlerts){                    
    
                    let filteredAlerts=[]                                
                    resp.data.ComplianceAlerts.forEach( item=>{                                     
                        
                        item.Hostname = item.Host.Address
                        item.OperSys = item.Host.OS
    
                        filteredAlerts.push(item)
    
                    })

                    changeIsLoading(false)
    
                    return filteredAlerts
    
                }                   
                
                changeIsLoading(false)
            })        

        } else {
            return Axios.post(Api.retrieveComplianceAlerts, {
                PageIndex: tablePageIndex,
                PageSize: tablePageSize,
                SortField: tableSortField,
                SortDirection: tableSortDirection,
                Filters: tableFilters
            }).then(resp => {
                
    
                if (resp.data && resp.data.ComplianceAlerts){                    
    
                    let filteredAlerts=[]                                
                    resp.data.ComplianceAlerts.forEach( item=>{                                     
                        
                        item.Hostname = item.Host.Hostname

                        if (!item.Hostname || item.Hostname === "") {
                            item.Hostname = item.Host.Address
                        }

                        item.OperSys = item.Host.OS
                        if (item.ScanCounter > 0) {
                            item.Timestamp = new Date(item.ScanCounter * 1000).toGMTString();
                        } else {
                            item.Timestamp = "N/A"
                        }
    
                        filteredAlerts.push({
                            ...item,
                            "ID_OS": item.StigID.startsWith("CS-OSQUERY") ? `${item.StigID}:::osquery` : `${item.StigID}:::${item.Host.OsClass}`
                        })
    
                    })
    
                    changeTableData(filteredAlerts)                        
                }   
    
                if (resp.data && resp.data.Total){                    
                    changeTableTotalSize(resp.data.Total)    
                } 
    
                if (resp.data && resp.data.Severity){                       
                    changeByRiskData(
                        Object.entries(resp.data.Severity).map( ([k,v])=>({
                            id: k,
                            label: k,
                            value: v
                        }))
                    )
                } 
    
                if (resp.data && resp.data.Status){                    
                    changeByStatusData(
                        Object.entries(resp.data.Status).map( ([k,v])=>({
                            id: k,
                            label: k,
                            value: v
                        }))
                    )    
                } 
    
                if (resp.data && resp.data.Host){                    
                    changeByHostData(
                        Object.entries(resp.data.Host).map( ([k,v])=>({
                            id: k,
                            label: k,
                            value: v
                        })).sort( (a,b) => {
                            return (a.value > b.value)*-1
                        }).slice(0, 20)
                    )    
                } 
                
                changeIsLoading(false)
                return resp.data

            })    
             
        }
        
        
    }

    useEffect(()=> {
        populateData()
    }, [tableFilters, tablePageIndex, tablePageSize, tableSortDirection, tableSortField])

    const handleChangeTableFilters = (newFilters) => {
        changeTablePageIndex(0)
        changeTableFilters(newFilters)
    }

    const toggleDetails = (item, update) => {
        const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };
        
        if (itemIdToExpandedRowMapValues[item.ID]) {
          delete itemIdToExpandedRowMapValues[item.ID];          
          if (!update) {            
            setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
            return
          }
        } 
        
        const listItems = [
        {
            title: 'Timestamp',
            description: `${item.Timestamp}`,
        },
        {
            title: 'Operating System',
            description: `${item.OperSys}`,
        },
        {
            title: 'Description',
            description: <EuiText style={{whiteSpace: "pre-wrap"}}>{item.Description}</EuiText>,
        },            
        {
            title: 'Notes',
            description: <>
            <EuiText>
                {item.Notes}
            </EuiText>
            <EuiButton
                style={{margin: 5}}
                iconSide="left" 
                iconType="documentEdit" 
                onClick={()=> {
                    changeSelectedNote(item.ID)
                    changeSelectedNoteText(item.Notes)
                    changeNotesFlyoutVisible(true)
                }}
            >
                Change Note
            </EuiButton>
            </>,
        },
        
        ];
        itemIdToExpandedRowMapValues[item.ID] = (
        <EuiDescriptionList listItems={listItems} />
        );
        
        setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
      };


    
    return <Fragment>
        <EuiPageHeader>
            <EuiPageHeaderSection>
                <EuiSpacer />
                <EuiFlexGroup direction="row">
                    <EuiFlexItem grow={5}>
                        <EuiTitle size="l">
                            <h1>Compliance Alerts</h1> 
                        </EuiTitle>
                    </EuiFlexItem>
                </EuiFlexGroup>                            
            </EuiPageHeaderSection>
            </EuiPageHeader>
            <EuiPageContent>
                <EuiPageContentBody>
                <LoadingModal isLoading={isLoading} />
                { pdfContent?  pdfContent :
                    TableData.length === 0 ?//&& !hasFilters(filters)? 
                        <>
                            <EuiFlexGroup gutterSize="l">
                                <EuiFlexItem>
                                    <EuiCard
                                        icon={<EuiIcon size="xxl" type="savedObjectsApp" />}
                                        title="No Scan Data Available"
                                        description="Looks like you haven't run any scans yet. Download the agent and run it at least once to see results here."
                                        footer={
                                        <div>
                                                <EuiButton style={{marginLeft: 5}} onClick={()=>window.open("https://docs.tutela.cybersift.io", '_blank')}>
                                                    Documentation and FAQs
                                                </EuiButton>                                         
                                            <EuiSpacer size="s" />   
                                            <EuiText size="s">
                                                <p>
                                                    Or pull the agent from docker:
                                                    <EuiCodeBlock
                                                        language="bash"
                                                        fontSize="m"
                                                        paddingSize="m"
                                                        isCopyable>
                                                            {"docker pull dvassallocs/tutela"}
                                                    </EuiCodeBlock>                                                     
                                                </p>                                                
                                            </EuiText>                                         
                                        </div>
                                        }
                                    />
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </>

                        : <>
                <EuiSpacer />       
                <EuiFlexGroup direction="row" style={{marginBottom:10}} gutterSize="s">
                    <EuiFlexItem grow={false}>
                        <EuiIcon type="documentation" size="m" shape="circle" color="primary" />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <em>CSV Reports can be generated by scolling down and clicking on "Export To CSV". CSV reports hold unlimited items </em>
                    </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup direction="row" style={{marginBottom:10}} gutterSize="s">
                    <EuiFlexItem grow={false}>
                        <EuiIcon type="documentation" size="m" shape="circle" color="primary" />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false} style={{maxWidth: "45vw"}}>
                        <em>PDF Reports can be generated by scolling down and clicking on "Generate PDF Report". PDF reports are limited to what is displayed in the browser since large PDF reports will crash your browser </em>
                    </EuiFlexItem>                    
                </EuiFlexGroup>
                <EuiSpacer />   
                <EuiFlexGroup direction="row">
                    <EuiFlexItem>
                        <h3>Alerts by Severity</h3>
                        <div style={{height: "350px"}}>
                            <AlertByRisk data={ByRiskData} theme={graphTheme} clickHandler={(e) => handleChangeTableFilters({
                                ...tableFilters,
                                Severity: e.id
                            })}/>
                        </div>
                    </EuiFlexItem>        
                    <EuiFlexItem>
                        <h3>Alerts by Host</h3>
                        <h5>Top 20</h5>
                        <div style={{height: "350px"}}>
                            <AlertByRisk data={ByHostData} theme={graphTheme} clickHandler={(e) => handleChangeTableFilters({
                                ...tableFilters,
                                Host: e.id
                            })}/>
                        </div>
                    </EuiFlexItem>                    
                    <EuiFlexItem>
                        <h3>Alerts by Status</h3>
                        <div style={{height: "350px"}}>
                            <AlertByRisk data={ByStatusData} theme={graphTheme} clickHandler={(e) => handleChangeTableFilters({
                                ...tableFilters,
                                Status: e.id
                            })}/>
                        </div>
                    </EuiFlexItem> 
                </EuiFlexGroup>
                <EuiSpacer />    
                <EuiFlexGroup direction='row'>
                    <EuiFlexItem>
                        <EuiSearchBar
                            box={{
                                incremental: false,
                                schema: true
                            }}
                            onChange={onSearchChange}
                        />  
                    </EuiFlexItem>
                    <EuiFlexItem
                        style={{padding: 10}}
                        grow={false}
                    >
                        <EuiSwitch                            
                            label="Show Only Pending"
                            checked={showOnlyPending}
                            onChange={(e) => changeShowOnlyPending(e.target.checked)}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>                               
                <EuiBasicTable
                    items={TableData}
                    columns={[
                        {
                            field: 'ID',
                            name: 'ID',
                            width: "60px"                            
                        },
                        {
                            field: 'Resolved',
                            name: 'Resolved',
                            width: "100px",
                            render: resolved => {
                                const color = resolved ? 'success' : 'danger';
                                const label = resolved ? 'Resolved' : 'Pending';
                                return <EuiHealth color={color}>{label}</EuiHealth>;
                            }
                        },
                        {
                            field: 'Hostname',
                            name: 'Host',
                            width: "200px",
                            sortable: true
                        },
                        {
                            field: 'ID_OS',
                            name: 'STIG ID',
                            width: "250px",
                            sortable: true,
                            render: (stigID) => {
                                let info = stigID.split(":::")
                                let id = info[0]
                                let os = info[1] 
                                let os_link = os_class_documentation_lookup[os] || "unknown"

                                return <a 
                                    href={`${os_link}?params=%7B"ds0.url_id":"${id.toLowerCase().replace('_', '')}"%7D`}
                                    target='_blank' rel="noreferrer">
                                    {id}
                                </a>
                            }
                        },
                        {
                            field: 'Severity',
                            name: 'Severity',
                            width: "100px",
                            sortable: true
                        },
                        {
                            field: 'Title',
                            name: 'Title',
                            sortable: false
                        },
                        {
                            actions: [{
                                align: RIGHT_ALIGNMENT,
                                width: '40px',
                                isExpander: true,
                                render: (item) => (
                                  <EuiButtonIcon
                                    onClick={() => toggleDetails(item)}
                                    aria-label={itemIdToExpandedRowMap[item.ID] ? 'Collapse' : 'Expand'}
                                    iconType={itemIdToExpandedRowMap[item.ID] ? 'arrowUp' : 'arrowDown'}
                                  />
                                ),
                            },
                            {
                                name: "Actions",
                                description: "Actions",
                                render: (item) => {
                                    return <>
                                        <EuiButton
                                                style={{margin: 5}}
                                                iconSide="left"
                                                iconType="checkInCircleFilled"
                                                onClick={()=> Axios.post(Api.resolveComplianceAlert, {
                                                    ID: item.ID,
                                                    Resolved: !item.Resolved
                                                }).then(resp=>populateData())} 
                                            >
                                                Toggle Resolved
                                        </EuiButton>
                                    </>
                                  }
                            }                                 
                        ]}
                    ]}
                    pagination={{
                        pageIndex: tablePageIndex,
                        pageSize: tablePageSize,
                        totalItemCount: tableTotalSize,
                        pageSizeOptions: [100, 50, 10, 0],
                        showPerPageOptions: true,
                    }}
                    sorting={{
                        sort: {
                          field: tableSortField,
                          direction: tableSortDirection
                        }
                      }}
                    itemId="ID"
                    itemIdToExpandedRowMap={itemIdToExpandedRowMap}
                    onChange={onTableChange}
                />        
                <EuiSpacer />
                <EuiButton color="primary" style={{margin:15}} onClick={handleGeneratePDF}>
                    Generate PDF Report
                </EuiButton>
                <ExportAsCSV dataView="COMPLIANCE" filters={tableFilters}/>
                </>
                }
                                
                </EuiPageContentBody>                            
            </EuiPageContent>        
            {notesFlyoutVisible ? <NotesFlyout 
                                noteText={selectedNoteText} 
                                handleVisibility={()=>changeNotesFlyoutVisible(false)} 
                                handleUpdateNote={handleNoteUpdate} />
            : null}
            <FilterBar filters={tableFilters} discardClickHandler={()=>changeTableFilters(DEFAULT_FILTERS)}/>
            
    </Fragment>
}

export default ComplianceTableV2