import { Text, View, StyleSheet, Page } from '@react-pdf/renderer';
import React, { Fragment } from 'react'

const severityToLevel = severity => {
    switch (severity) {
        case 'CRITICAL':            
            return 7;
        case 'HIGH':            
            return 6;
        case 'MEDIUM':            
            return 5;
        case 'LOW':            
            return 4;
        case 'INFO':            
            return 3;
        default:
            return 0;
    }
}

export default ({data}) => {
    
    const styles = StyleSheet.create({
        table: { 
            display: "table", 
            width: "auto", 
            margin:"auto",
            borderStyle: "solid", 
            borderWidth: 1, 
            borderRightWidth: 0, 
            borderBottomWidth: 0 
        }, 
        tableRow: { 
            margin: "auto", 
            flexDirection: "row" 
        }, 
        tableSpacerRow: { 
            margin: "auto",
            flexDirection: "row",
            backgroundColor: "lightblue"
        }, 
        tableKeyCol: { 
            width: "25%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0 
        }, 
        tableSpacerCol: { 
            width: "100%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0 
        }, 
        tableValueCol: { 
            width: "75%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0 
        },
        tableCell: { 
            margin: "auto", 
            marginLeft: 5,
            marginRight: 5,
            marginBottom: 5,
            marginTop: 5, 
            fontSize: 10 
        }
    });

    const tableDataFormatted = data
        .sort((a,b)=>{

            const aLevel = severityToLevel(a.Severity)
            const bLevel = severityToLevel(b.Severity)
            
            if (aLevel > bLevel){
                return -1
            } else if (aLevel < bLevel){
                return 1
            }       

            return 0                 

        })
        .map((datum, outerIdx) => {

            let counter = 0

                const content = []
                Object.entries(datum).forEach( ([key, value], idx) => {
                    if (key==="Address"){
                        value = value.join(", ")
                    }

                    if (key==="Host"){
                        return
                    }

                    counter ++
                    if (key !== "Resolved"){
                        content.push( <View key={counter} style={styles.tableRow}> 
                                <View style={styles.tableKeyCol}> 
                                    <Text style={styles.tableCell}>
                                        {key}
                                    </Text> 
                                </View>
                                <View style={styles.tableValueCol}> 
                                    <Text style={styles.tableCell}>
                                        {value}
                                    </Text> 
                                </View>                                             
                            </View> 
                        )}                    
                })

                return <Fragment key={`outer-${outerIdx}`}>
                    {content}
                    <View style={styles.tableSpacerRow}> 
                        <View style={styles.tableSpacerCol}> 
                            <Text style={styles.tableCell}>                                        
                            </Text> 
                        </View>    
                    </View>  
                </Fragment>                        
        })  
    
    
    return (
        <View style={styles.table}>             
               {tableDataFormatted} 
        </View>
    )
    
}