import React, { useEffect, useState } from 'react'
import "./StackedProgress.css";

const StackedProgress = ({low = 0, medium = 0, high = 0, critical = 0}) => {


    const [widths, changeWidths] = useState({
        'critical': 0,
        'high': 0,
        'medium': 0,
        'low': 0,
    })

    useEffect(()=>{
        let total = low + medium + high + critical

        if (total > 0) {
            total = low + medium + high + critical
        } else {
            total = 1            
        }

        let lowPrcnt = (low/total) * 100
        let mediumPrcnt = (medium/total) * 100
        let highPrcnt = (high/total) * 100
        let criticalPrcnt = (critical/total) * 100
        
        if ( lowPrcnt !== 0 && 10 > lowPrcnt ) {
            lowPrcnt = 10
        }
        if ( mediumPrcnt !== 0 && 10 > mediumPrcnt ) {
            mediumPrcnt = 10
        }
        if ( highPrcnt !== 0 && 10 > highPrcnt ) {
            highPrcnt = 10
        }
        if ( criticalPrcnt !== 0 && 10 > criticalPrcnt ) {
            criticalPrcnt = 10
        }

        let newPrctTotal = criticalPrcnt + highPrcnt + mediumPrcnt + lowPrcnt
        criticalPrcnt = (criticalPrcnt / newPrctTotal) * 100
        highPrcnt = (highPrcnt / newPrctTotal) * 100
        mediumPrcnt = (mediumPrcnt / newPrctTotal) * 100
        lowPrcnt = (lowPrcnt / newPrctTotal) * 100

        
        changeWidths({
            'critical': criticalPrcnt,
            'high': highPrcnt,
            'medium': mediumPrcnt,
            'low': lowPrcnt,
        })
    },[low, medium, high, critical])

    return <div id="progress" className="progress" style={{width: "100%"}}>
            {   low + medium + high +critical > 0 ? 

                <>
                    { low > 0 && 
                        <div 
                            className="progress-bar bg-low" 
                            style={{width: widths.low+"%", cursor: 'pointer'}}
                            onClick={()=>{}} //TODO
                        >
                        <span style={{color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100%"}}>
                            {low}
                        </span>
                        </div>
                    }
                    
                    { medium > 0 && 
                        <div 
                            className="progress-bar bg-medium" 
                            style={{width: widths.medium+"%", cursor: 'pointer'}}
                            onClick={()=>{}} //TODO
                        >
                        <span style={{color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100%"}}>
                            {medium}
                        </span>
                        </div>
                    }

                    { high > 0 && 
                        <div 
                            className="progress-bar bg-high" 
                            style={{width: widths.high+"%", cursor: 'pointer'}}
                            onClick={()=>{}} //TODO
                        >
                        <span style={{color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100%"}}>
                            {high}
                        </span>
                        </div>
                    }

                    { critical > 0 && 
                        <div 
                            className="progress-bar bg-critical" 
                            style={{width: widths.critical+"%", cursor: 'pointer'}}
                            onClick={()=>{}} //TODO
                        >
                        <span style={{color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100%"}}>
                            {critical}
                        </span>
                        </div>
                    }
                </>

                :
                <span style={{color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100%"}}>
                    N/A
                </span>
            }
            
        </div>    

}

export default StackedProgress