import { Text, View, StyleSheet } from '@react-pdf/renderer';
import React from 'react'


export default ({headers, data}) => {
    
    const styles = StyleSheet.create({
        table: { 
            display: "table", 
            width: "75vw", 
            margin:"auto",
            borderStyle: "solid", 
            borderWidth: 1, 
            borderRightWidth: 0, 
            borderBottomWidth: 0 
        }, 
        tableRowHeader: { 
            margin: "auto", 
            flexDirection: "row",
            fontWeight: "bold",
            backgroundColor: "lightblue"
        }, 
        tableRow: { 
            margin: "auto", 
            flexDirection: "row" 
        }, 
        tableCol: { 
            width: "50%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0 
        }, 
        tableCell: { 
            margin: "auto", 
            marginTop: 5, 
            marginBottom: 3, 
            fontSize: 10 
        },
        tableCellImportant: { 
            margin: "auto", 
            marginTop: 8, 
            marginBottom: 8, 
            fontSize: 14
        }
    });
    
    let counter = 0

    return (
        <View style={styles.table}> 
            {/* TableHeader */} 
            <View style={styles.tableRowHeader}> 
                {headers.map( header => {
                counter++
                return <View style={styles.tableCol} key={`header-${counter}`}> 
                        <Text style={styles.tableCell}>
                            {header}
                        </Text> 
                    </View> 
                })}                                
            </View> 
            {/* TableContent */}             
                {data.map( datum => {
                    counter++
                    let cellStyle = datum.important ? styles.tableCellImportant : styles.tableCell
                    return <View style={styles.tableRow} key={`content-${counter}`}> 
                        <View style={styles.tableCol}> 
                            <Text style={cellStyle}>
                                {datum['id']}
                            </Text> 
                        </View>
                        <View style={styles.tableCol}> 
                            <Text style={cellStyle}>
                                {datum['value']}
                            </Text> 
                        </View>                                                                      
                    </View> 
                })}  
        </View>
    )
    
}