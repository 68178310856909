import { ResponsivePieCanvas } from '@nivo/pie'
import React from 'react'

const VulnByExploit = (props) => {
    return <ResponsivePieCanvas
        data={props.data}
        pixelRatio={1}
        height={props.height? props.height : 350}
        width={props.height? props.height : 350}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ scheme: 'nivo' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor={props.report ? "black" : "wheat"}
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: 'color' }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}   
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        theme={props.theme}     
        onClick={props.clickHandler}                   
    />
}

export default VulnByExploit