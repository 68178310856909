import React, { Fragment, useEffect, useState } from 'react'
import { EuiInMemoryTable,  EuiButtonIcon,  EuiCard, EuiCodeBlock, EuiDescriptionList, EuiFlexGroup, EuiFlexItem, EuiHealth, EuiIcon, EuiSearchBar, EuiSwitch, EuiText, RIGHT_ALIGNMENT, EuiFacetGroup, EuiFacetButton } from '@elastic/eui';
import {
    EuiButton,
    EuiPageContent,
    EuiPageContentBody,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiSpacer,
    EuiTitle,
  } from '@elastic/eui';
import Axios from 'axios';
import Api from '../../Api';
import AlertByRisk from '../WebScanWidgets/AlertByRisk';
import LoadingModal from '../CommonWidgets/LoadingModal';
import FilterBar from '../CommonWidgets/FilterBar';
import ExportAsCSV from '../CommonWidgets/ExportAsCSV';

const DEFAULT_FILTERS = {
    Filter: "",
    Status: "",    
    Resource: "",
    FriendlyName: ""
}


const graphTheme = {
    axis: {
        ticks: {
            text:{
                fill: "wheat"
            }
        },
        legend: {
            text: {
                fill: "wheat"
            }
        }
    },
    tooltip: {
        container: {
            background: '#333',
        },
    },
}

const CloudComplianceTable = () => {

    const [isLoading, changeIsLoading] = useState(true)
    const [TableData, changeTableData] = useState([])    

    
    const [ByStatusData, changeByStatusData] = useState([])    
    const [ByResourceData, changeByResourceData] = useState([])    
    const [ByTitleData, changeByTitleData] = useState([])    
        

    const [tablePageIndex, changeTablePageIndex] = useState(0)
    const [tablePageSize, changeTablePageSize] = useState(10)
    const [tableTotalSize, changeTableTotalSize] = useState(0)
    const [tableSortField, changeTableSortField] = useState('Title')
    const [tableSortDirection, changeTableSortDirection] = useState('asc')
    const [tableFilters, changeTableFilters] = useState(DEFAULT_FILTERS)    
    const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState({});

    const [showOnlyPending, changeShowOnlyPending] = useState(false)
    const [checkTitles, changeCheckTitles] = useState([])
    
    const onTableChange = ({page, sort}) => {
        changeTablePageIndex(page.index)
        changeTablePageSize(page.size)
        changeTableSortDirection(sort.direction)
        changeTableSortField(sort.field)
    }

    const onSearchChange = (e)=>{
        
        e.query.ast.clauses.length > 0 && e.query.ast.clauses[0].type === "field" ? handleChangeTableFilters({
            ...tableFilters,
            Filter: e.query.ast.clauses[0].value
        }) : handleChangeTableFilters({
            ...tableFilters,
            Filter: e.queryText
        })
    }

    const toggleDetails = (item) => {
        const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };
        if (itemIdToExpandedRowMapValues[item.ID]) {
          delete itemIdToExpandedRowMapValues[item.ID];
        } else {
            const listItems = [
            {
              title: 'Description',
              description: <EuiText style={{whiteSpace: "pre-wrap", margin: 10}}>{item.Description}</EuiText>,
            }            
          ];
          itemIdToExpandedRowMapValues[item.ID] = (
            <EuiDescriptionList listItems={listItems} />
          );
        }
        setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
      };


    
    useEffect(()=>{
        handleChangeTableFilters({
            ...tableFilters,
            Status: showOnlyPending ? 'alarm' : ''
        })
    },[showOnlyPending])
    

    const populateData = () => {     
        changeIsLoading(true)
        
            Axios.post(Api.retrieveCloudComplianceResults, {
                ...tableFilters
            }).then(resp => {
                
                    
                resp.data.Results && changeTableData(resp.data.Results)
                resp.data.Results && changeTableTotalSize(resp.data.Results.length)
                resp.data.Results && changeCheckTitles(resp.data.Results.map(elem => elem.Title).filter((val, idx, array) => array.indexOf(val)===idx).map(elem => ({
                    value: elem,
                    name: elem,
                    label: elem
                })))


                resp.data.GroupByStatus && changeByStatusData(Object.values(resp.data.GroupByStatus).map( v =>({
                    id: v.Status,
                    label: v.Status,
                    value: v.Count
                })))

                resp.data.GroupByResource && changeByResourceData(Object.values(resp.data.GroupByResource).map( v =>({
                    id: v.Resource,
                    label: v.Resource,
                    value: v.Count
                })))       
                
                resp.data.GroupByTitle && changeByTitleData(Object.values(resp.data.GroupByTitle).map( v =>({
                    id: v.Title,
                    label: v.Title,
                    value: v.Count
                })))       
                   
                
                changeIsLoading(false)
                
    
            })        
        
        
        
    }

    useEffect(()=>{
        populateData()
    }, [tableFilters])//, tablePageIndex, tablePageSize, tableSortDirection, tableSortField])

    const handleChangeTableFilters = (newFilters) => {
        changeTablePageIndex(0)
        changeTableFilters(newFilters)
    }

    const statusColor = status => {
        switch (status) {
            case "info": return "primary"
            case "alarm": return "danger"
            case "ok": return "success"
            default: return "primary"
        }
    }

    
    return <Fragment>
        <EuiPageHeader>
            <EuiPageHeaderSection>
                <EuiSpacer />
                <EuiFlexGroup direction="row">
                    <EuiFlexItem grow={5}>
                        <EuiTitle size="l">
                            <h1>Cloud Compliance Alerts</h1> 
                        </EuiTitle>
                    </EuiFlexItem>
                </EuiFlexGroup>                            
            </EuiPageHeaderSection>
            </EuiPageHeader>
            <EuiPageContent>
                <EuiPageContentBody>
                <LoadingModal isLoading={isLoading} />
                { tableTotalSize === 0 ?//&& !hasFilters(filters)? 
                        <>
                            <EuiFlexGroup gutterSize="l">
                                <EuiFlexItem>
                                    <EuiCard
                                        icon={<EuiIcon size="xxl" type="savedObjectsApp" />}
                                        title="No Scan Data Available"
                                        description="Looks like you haven't run any scans yet. Download the agent and run it at least once to see results here."
                                        footer={
                                        <div>
                                                <EuiButton style={{marginLeft: 5}} onClick={()=>window.open("https://docs.tutela.cybersift.io", '_blank')}>
                                                    Documentation and FAQs
                                                </EuiButton>                                         
                                            <EuiSpacer size="s" />   
                                            <EuiText size="s">
                                                <p>
                                                    Or pull the agent from docker:
                                                    <EuiCodeBlock
                                                        language="bash"
                                                        fontSize="m"
                                                        paddingSize="m"
                                                        isCopyable>
                                                            {"docker pull dvassallocs/tutela"}
                                                    </EuiCodeBlock>                                                     
                                                </p>                                                
                                            </EuiText>                                         
                                        </div>
                                        }
                                    />
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </>

                        : <>

                <EuiSpacer />     

                <EuiFlexGroup direction="row" style={{marginBottom:10}} gutterSize="s">
                    <EuiFlexItem grow={false}>
                        <EuiIcon type="documentation" size="m" shape="circle" color="primary" />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <em>CSV Reports can be generated by scolling down and clicking on "Export To CSV". CSV reports hold unlimited items </em>
                    </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer />   
                <EuiFlexGroup direction="row">                                  
                    <EuiFlexItem>
                        <h3>Alerts by Status</h3>
                        <div style={{height: "350px"}}>
                            <AlertByRisk data={ByStatusData} theme={graphTheme} clickHandler={(e) => handleChangeTableFilters({
                                ...tableFilters,
                                Status: e.id
                            })}/>
                        </div>
                    </EuiFlexItem> 

                    <EuiFlexItem>
                        <h3>Alerts by Title (Top 25)</h3>
                        <div style={{height: "350px"}}>
                            <AlertByRisk data={ByTitleData} theme={graphTheme} clickHandler={(e) => handleChangeTableFilters({
                                ...tableFilters,
                                Title: e.id
                            })}/>
                        </div>
                    </EuiFlexItem> 

                    <EuiFlexItem>
                        <h3>Alerts by Resource (Top 25)</h3>
                        <div style={{height: "350px"}}>
                            <AlertByRisk data={ByResourceData} theme={graphTheme} clickHandler={(e) => handleChangeTableFilters({
                                ...tableFilters,
                                Resource: e.id
                            })}/>
                        </div>
                    </EuiFlexItem> 
                </EuiFlexGroup>
                <EuiSpacer />    
                <EuiFlexGroup direction='row'>
                    <EuiFlexItem
                        style={{padding: 10}}
                        grow={false}
                    >
                        <EuiSwitch                            
                            label="Show Only Pending"
                            checked={showOnlyPending}
                            onChange={(e) => changeShowOnlyPending(e.target.checked)}
                        />
                    </EuiFlexItem>                    
                </EuiFlexGroup>    
                <EuiFlexGroup direction='row'>
                    {/* <EuiFlexItem>
                        <EuiSearchBar
                            box={{
                                incremental: false,
                                schema: true
                            }}
                            onChange={onSearchChange}
                        />  
                    </EuiFlexItem> */}
                </EuiFlexGroup>      
                
                        <EuiInMemoryTable
                            search={{
                                width: '20%',
                                box:{
                                    incremental: false,
                                    schema: true
                                },
                                onChange: onSearchChange,
                                filters: [
                                    {
                                        type: 'field_value_selection',
                                        field: 'Title',
                                        name: 'Title',
                                        multiSelect: false,
                                        options: checkTitles,
                                      },
                                ]
                            }}
                            items={TableData}
                            columns={[ 
                                {
                                    field: 'FriendlyName',
                                    name: 'Cloud',        
                                    width: '10%',                    
                                },
                                {
                                    field: 'Status',
                                    name: 'Status',     
                                    width: '10%',
                                    render: e => <EuiHealth color={statusColor(e)}>{e}</EuiHealth>
                                },
                                {
                                    field: 'Resource',
                                    name: 'Resource', 
                                    width: '20%',                           
                                },
                                {
                                    field: 'Title',
                                    name: 'Title',                            
                                },
                                {
                                    field: 'Reasons',
                                    name: 'Reason',   
                                    width: '25%',
                                },
                                {
                                    actions: [{
                                        align: RIGHT_ALIGNMENT,
                                        width: '40px',
                                        isExpander: true,
                                        render: (item) => (
                                        <EuiButtonIcon
                                            onClick={() => toggleDetails(item)}
                                            aria-label={itemIdToExpandedRowMap[item.ID] ? 'Collapse' : 'Expand'}
                                            iconType={itemIdToExpandedRowMap[item.ID] ? 'arrowUp' : 'arrowDown'}
                                        />
                                        ),
                                    }]
                                }                        
                            ]}
                            pagination={{
                                pageIndex: tablePageIndex,
                                pageSize: tablePageSize,
                                totalItemCount : tableTotalSize,
                                pageSizeOptions: [100, 50, 10],
                                showPerPageOptions: true,
                            }}
                            sorting={{
                                sort: {
                                field: tableSortField,
                                direction: tableSortDirection
                                }
                            }}
                            itemId="ID"
                            itemIdToExpandedRowMap={itemIdToExpandedRowMap}
                            onChange={onTableChange}
                        />
                                                            
                <EuiSpacer />                
                <ExportAsCSV dataView="CLOUD_COMPLIANCE" filters={tableFilters} data={TableData}/>
                </>
                }
                                
                </EuiPageContentBody>                            
            </EuiPageContent>        
            
            <FilterBar filters={tableFilters} discardClickHandler={()=>changeTableFilters(DEFAULT_FILTERS)}/>
            
    </Fragment>
}

export default CloudComplianceTable