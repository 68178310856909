import React, { useEffect, useState } from 'react';

import {
  EuiPopover,
  EuiPopoverTitle,
  EuiFieldSearch,
  EuiFilterSelectItem,
  EuiLoadingChart,
  EuiSpacer,
  EuiFilterGroup,
  EuiFilterButton,
} from '@elastic/eui';

export default ({scanData, onScanChanged}) => {

  const [items, changeItems] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [currentScan, changeCurrentScan] = useState(false);
  const [filterText, changeFilterText] = useState(false)

  const onButtonClick = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  const handleScanChoice = scanName => {
      if (currentScan === scanName){
          scanName = false
      } 
      changeCurrentScan(scanName)  
      if(onScanChanged){
          onScanChanged(scanName)
      }
  }  

  useEffect(()=>{
    if (!scanData){
      return
    }

    changeItems ( scanData.sort( (a,b)=> -1 * (a.Timestamp - b.Timestamp) ).map(scan => {

        if (scan.ScanName === currentScan){
            return {
                name: scan.ScanName,
                checked: "on"
            }
        }
        return {
            name: scan.ScanName
        }
    }) )
          
  },[scanData, currentScan])

  const button = (
    <EuiFilterButton
      iconType="arrowDown"
      onClick={onButtonClick}
      isSelected={isPopoverOpen}
      numFilters={items.length}
      hasActiveFilters={currentScan ? true : false}
      numActiveFilters={currentScan ? 1 : 0}
    >
        Scans
    </EuiFilterButton>
  );

  return (
    <EuiFilterGroup>
      <EuiPopover
        id="popoverExampleMultiSelect"
        ownFocus
        button={button}
        isOpen={isPopoverOpen}
        closePopover={closePopover}
        panelPaddingSize="none"
      >
        <EuiPopoverTitle>
          <EuiFieldSearch onChange={(e)=>changeFilterText(e.target.value)}/>
        </EuiPopoverTitle>
        <div className="euiFilterSelect__items">
          {items ? 
            items.filter(item=>filterText?item.name.includes(filterText):true).map((item, index) => (
                <EuiFilterSelectItem checked={item.checked} key={index} onClick={(e)=>{
                    handleScanChoice(e.target.innerText)
                }}>
                {item.name}
                </EuiFilterSelectItem>
            )) 
          :
            <div className="euiFilterSelect__note">
                <div className="euiFilterSelect__noteContent">
                    <EuiLoadingChart size="m" />
                    <EuiSpacer size="xs" />
                    <p>Loading filters</p>
                </div>
            </div>
        }
          
          {/*
              Use when no results are returned
            */}
          {/* <div className="euiFilterSelect__note">
            <div className="euiFilterSelect__noteContent">
              <EuiIcon type="minusInCircle" />
              <EuiSpacer size="xs" />
              <p>No filters found</p>
            </div>
          </div> */}
        </div>
      </EuiPopover>
    </EuiFilterGroup>
  );
};