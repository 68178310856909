import React, { useEffect, useState } from 'react';

import {
  EuiPopover,
  EuiPopoverTitle,
  EuiFieldSearch,
  EuiFilterSelectItem,
  EuiLoadingChart,
  EuiSpacer,
  EuiIcon,
  EuiFilterGroup,
  EuiFilterButton,
} from '@elastic/eui';

export default ({hostGroupData, onHostGroupChanged}) => {

  const [items, changeItems] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [currentHostGroup, changeCurrentHostGroup] = useState(false);
  const [filterText, changeFilterText] = useState(false)

  const onButtonClick = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  const handleHostgroupChoice = hostGroup => {
      if (currentHostGroup === hostGroup){
          hostGroup = false
      } 
      changeCurrentHostGroup(hostGroup)  
      if(onHostGroupChanged){
          onHostGroupChanged(hostGroup)
      }
  }  

  useEffect(()=>{
    changeItems ( hostGroupData.map(hostGroup => {

        if (hostGroup.Text === currentHostGroup){
            return {
                name: hostGroup.Text,
                checked: "on"
            }
        }
        return {
            name: hostGroup.Text
        }
    }) )      
  },[hostGroupData, currentHostGroup])

  const button = (
    <EuiFilterButton
      iconType="arrowDown"
      onClick={onButtonClick}
      isSelected={isPopoverOpen}
      numFilters={items.length}
      hasActiveFilters={currentHostGroup ? true : false}
      numActiveFilters={currentHostGroup ? 1 : 0}
    >
        Host Groups
    </EuiFilterButton>
  );

  return (
    <EuiFilterGroup>
      <EuiPopover
        id="popoverExampleMultiSelect"
        ownFocus
        button={button}
        isOpen={isPopoverOpen}
        closePopover={closePopover}
        panelPaddingSize="none"        >
        <EuiPopoverTitle>
          <EuiFieldSearch onChange={(e)=>changeFilterText(e.target.value)}/>
        </EuiPopoverTitle>
        <div className="euiFilterSelect__items">
          {items ? 
            items.filter(item=>filterText?item.name.includes(filterText):true).map((item, index) => (
                <EuiFilterSelectItem checked={item.checked} key={index} onClick={(e)=>{
                    handleHostgroupChoice(e.target.innerText)
                }}>
                {item.name}
                </EuiFilterSelectItem>
            )) 
          :
            <div className="euiFilterSelect__note">
                <div className="euiFilterSelect__noteContent">
                    <EuiLoadingChart size="m" />
                    <EuiSpacer size="xs" />
                    <p>Loading filters</p>
                </div>
            </div>
        }
          
          {/*
              Use when no results are returned
            */}
          {/* <div className="euiFilterSelect__note">
            <div className="euiFilterSelect__noteContent">
              <EuiIcon type="minusInCircle" />
              <EuiSpacer size="xs" />
              <p>No filters found</p>
            </div>
          </div> */}
        </div>
      </EuiPopover>
    </EuiFilterGroup>
  );
};