export const IconMap = {
    "localhost": "https://avatars3.githubusercontent.com/u/25011180?s=280&v=4",
    "localhost-report": "./logo.png",
    "vas.cybersift.io": "https://avatars3.githubusercontent.com/u/25011180?s=280&v=4",
    "vas.cybersift.io-report": "./logo.png",
    "trial.tutela.cybersift.io": "https://avatars3.githubusercontent.com/u/25011180?s=280&v=4",
    "trial.tutela.cybersift.io-report": "./logo.png",
    "go.cybersift.io": "./img/logos/GO-Logo.png",
    "go.cybersift.io-report": "./img/logos/GO-Logo.png",
}

export const BackendMap = {
    "default": "https://cs-vas-backend.cybersift.io",
    "localhost": "https://cs-vas-backend.cybersift.io",//"https://trial-backend.tutela.cybersift.io",
    "trial.tutela.cybersift.io": "https://trial-backend.tutela.cybersift.io",
    "sparkasse.tutela.cybersift.io": "https://tutela.sbmalta.local",
}

export const CdnMap = {
    "default": "https://cdn.cybersift.io",
    "trial.tutela.cybersift.io": "https://cdn.trial.tutela.cybersift.io",    
}

export default IconMap