import { EuiDescriptionList, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiTitle } from '@elastic/eui'
import React, { useEffect, useState } from 'react'

export default ({ cveDetails, handleVisibility, serviceDetails = {} }) => {

    const [formattedDetails, changeFormattedDetails] = useState([])

    useEffect(()=>{
        
        const tempCveDetails = Object.entries(cveDetails).map( ([key, value], _) => ({
            title: key,
            description: value
        }) )

        const tempServiceDetails = Object.entries(serviceDetails)
            .filter( ([key, value], _) => key!=="ID")
            .filter( ([key, value], _) => key!=="HostID")
            .filter( ([key, value], _) => key!=="UserID")
            .map( ([key, value], _) => {                
                if (value===""){
                    value = "Unknown"
                }
                return {
                    title: key,
                    description: value
                }
            })

        const tempDetails = [...tempServiceDetails, ...tempCveDetails]
        
        changeFormattedDetails(tempDetails)
    },[cveDetails, serviceDetails])

    return <EuiFlyout
        onClose={() => handleVisibility(false)}
        aria-labelledby="detailsFlyout"
    >
        <EuiFlyoutHeader hasBorder>
            <EuiTitle size="m">
                <h2 id="flyoutTitle">CVE Details</h2>
            </EuiTitle>
        </EuiFlyoutHeader>

        <EuiFlyoutBody>
        
            <EuiDescriptionList textStyle="reverse" listItems={formattedDetails} />

        </EuiFlyoutBody>
    </EuiFlyout>

}