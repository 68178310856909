import React, { useEffect, useState } from 'react'
import {
    EuiButton,
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiPageContentBody,
    EuiPageContentHeader,
    EuiPageContentHeaderSection,
    EuiPageHeaderSection,
    EuiSpacer,
    EuiText,
    EuiTitle,
  } from '@elastic/eui';
import Axios from 'axios';
import Api from '../Api';

import VulnBySeverity from './DiscoverWidgets/VulnBySeverity';
import FilterBar from './CommonWidgets/FilterBar';

import ResolvedVsPending from './DiscoverWidgets/ResolvedVsPending';

import LoadingModal from './CommonWidgets/LoadingModal';
import VulnByLanguage from './DiscoverWidgets/VulnByLanguage';
import VulnByLibrary from './DiscoverWidgets/VulnByLibrary';
import VulnByProject from './DiscoverWidgets/VulnByProject';
import LibVulnTable from './DiscoverWidgets/LibVulnTable';

const chartHeight = window.innerWidth/6 //"350px"

const DEFAULT_FILTERS = {
    libraryFilter: false,
    languageFilter: false,
    projectFilter: false,
    severityFilter: false,
    resolutionFilter: false,
    searchQuery: false,
}

const hasFilters = (currentFilters) =>{
    let result = false
    Object.values(currentFilters).forEach(val=> {
        if(val !== false){
            result=true
        }
    })
    return result
}

const graphTheme = {
    axis: {
        ticks: {
            text:{
                fill: "wheat"
            }
        },
        legend: {
            text: {
                fill: "wheat"
            }
        }
    },
    tooltip: {
        container: {
            background: '#333',
        },
    },
}

const Library = () => {
    
    const [ByLibraryData, changeByLibraryData] = useState([])
    const [ByProjectData, changeByProjectData] = useState([])
    const [BySeverityData, changeBySeverityData] = useState([])
    const [ByLanguageData, changeByLanguageData] = useState([])
    const [ByResolutionData, changeByResolutionData] = useState([])    
    const [TableData, changeTableData] = useState([])
    const [discoveryData, changeDiscoveryData] = useState([])    
    const [filters, changeFilters] = useState(DEFAULT_FILTERS)
    const [sortField, changeSortField] = useState("Project")
    const [sortDirection, changeSortDirection] = useState("asc")
    const [isLoading, changeIsLoading] = useState(true)
    const [pageNumber, changePageNumber] = useState(0)
    const [totalItemCount, changeTotalItemCount] = useState(0)
    
    const getPageContent = (isLoading, totalItemCount, hasFilters) => {

        if (isLoading){
            return <LoadingModal isLoading={isLoading} />
        }

        if (totalItemCount === 0 && !hasFilters){
            return <>                       
                    <EuiFlexGroup gutterSize="l">
                        <EuiFlexItem>
                            <EuiCard
                                icon={<EuiIcon size="xxl" type="savedObjectsApp" />}
                                title="No Scan Data Available"
                                description="Looks like you haven't run any scans yet. Download the agent and run it at least once to see results here."
                                footer={
                                <div>
                                    <EuiButton style={{marginLeft: 5}} onClick={()=>window.open("https://docs.tutela.cybersift.io", '_blank')}>
                                        Documentation and FAQs
                                    </EuiButton>                                         
                                    <EuiSpacer size="xs" />                                                                               
                                </div>
                                }
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>                    
            </>
        }

        if (totalItemCount === 0){
            return <>                
                <EuiFlexGroup gutterSize="l">
                    <EuiFlexItem>
                        <EuiCard
                            icon={<EuiIcon size="xxl" type="savedObjectsApp" />}
                            title="No Vulnerabilies Detected with these filter criteria"
                            description="Remove filters to see more data."
                            footer={ 
                                <EuiText size="s">
                                    <p>
                                        This is a good thing... no vulnerabilities!
                                    </p>                                                
                                </EuiText>                                         
                            }
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>                
            </>
        }

        return <>
                <EuiPageContentHeader>
                    <EuiPageContentHeaderSection>
                    <EuiTitle>
                        <h2>Supply Chain Vulnerabilities</h2>                        
                    </EuiTitle>
                    <EuiFlexGroup direction="row" style={{marginBottom:10}} gutterSize="s">
                        <EuiFlexItem grow={false}>
                            <EuiIcon type="documentation" size="m" shape="circle" color="primary" />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <em> Supply chain vulnerabilites are detected in libraries used as dependencies in your developers' projects </em>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    </EuiPageContentHeaderSection>
                    <EuiPageHeaderSection>
                        
                    </EuiPageHeaderSection>
                </EuiPageContentHeader>
                <EuiPageContentBody> 
                <h3>Vulnerabilities by Severity</h3>
                <div style={{height: "200px"}}>
                    <VulnBySeverity data={BySeverityData} theme={graphTheme} clickHandler={(e) => {
                        changeFilters({
                        ...filters,
                        severityFilter: e.indexValue
                    })}}/>
                </div>
                <EuiSpacer />
                <EuiFlexGroup direction="row" alignItems="center">
                    <EuiFlexItem>
                        <h3>Top 20 Vulnerabilities by Language</h3>
                        <div style={{height: chartHeight}}>
                            <VulnByLanguage height={chartHeight} data={ByLanguageData} theme={graphTheme} clickHandler={(e) => changeFilters({
                                ...filters,
                                languageFilter: e.id
                            })}/>
                        </div>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <h3>Top 20 Vulnerabilities by Library</h3>
                        <div style={{height: chartHeight}}>
                            <VulnByLibrary height={chartHeight} data={ByLibraryData} theme={graphTheme} clickHandler={(e) => changeFilters({
                                ...filters,
                                libraryFilter: e.id
                            })}/>
                        </div>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <h3>Top 20 Vulnerabilities by Project</h3>
                        <div style={{height: chartHeight}}>
                            <VulnByProject height={chartHeight} data={ByProjectData} theme={graphTheme} clickHandler={(e) => changeFilters({
                                ...filters,
                                projectFilter: e.id
                            })}/>
                        </div>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <h3> Resolved vs Pending</h3>
                        <div style={{height: chartHeight}}>
                            <ResolvedVsPending height={chartHeight} data={ByResolutionData} theme={graphTheme} clickHandler={(e) => changeFilters({
                                ...filters,
                                resolutionFilter: e.id
                            })}/>
                        </div>
                    </EuiFlexItem>
                </EuiFlexGroup>    
                <EuiSpacer />

                <LibVulnTable 
                    data={TableData} 
                    searchFilterHandler={(id, filterType)=>{changeFilters({
                        ...filters,
                        [filterType]: id
                    })}}    
                    searchFilterData={{
                        "Severity": BySeverityData,
                        "Language": ByLanguageData,
                        "Poject": ByProjectData,
                        "Library": ByLibraryData,
                        "Resolution": ByResolutionData
                    }}                                                
                    initialSearch={filters.searchQuery}
                    pageIndex={pageNumber} 
                    totalItemCount={totalItemCount} 
                    setPageIndex={changePageNumber} 
                    handleSearchChange={handleSearchChange}
                    sortInfo={{
                        sortField: sortField,
                        sortDirection: sortDirection,
                        handleSortChange: handleSortChange
                    }}
                />
                    
                {/* <ExportAsCsvServerSide dataView="VULN" filters={filters}/> */}
                
                <EuiSpacer />
            </EuiPageContentBody> 
        </>   
    }
    

    const handleSearchChange = ({queryText}) => {
        console.log(queryText)
        changeFilters({
            ...filters,
            searchQuery: queryText
        })
    }

    const handleSortChange = (sortField, sortDirection)=>{
        changeSortDirection(sortDirection)
        changeSortField(sortField)
    }

    const populateData = () => {

        const tableData=[]                    
       
        discoveryData.forEach(cve => {               

            tableData.push({
                id: `${cve.Project}:${cve.CveID}:${cve.Library.Name}`,
                Language: cve.Library.Language,
                Version: cve.Library.Version,
                Library: cve.Library.Name,
                Project: cve.Project,
                Severity: cve.Cve.Severity === "" ? "INFO" : cve.Cve.Severity,
                CveDetails: cve.Cve,
                CveID: cve.CveID,
                Resolved: cve.Resolved,
                CveDescription: cve.Cve.Description,
                Notes: cve.Notes
            })

        });

        changeTableData(tableData)
            
    }

    useEffect(()=>{        
        populateData()
    },[discoveryData])

    
    
    useEffect(()=>changePageNumber(0),[filters])

    useEffect(()=>{

        changeIsLoading(true)

        Axios.post(Api.retrieveLibraryCVEs+`/${pageNumber}`, {
            Project: filters.projectFilter.toString(),
            Language: filters.languageFilter.toString(),
            LibraryName: filters.libraryFilter.toString(),
            Severity: filters.severityFilter.toString(),
            Status: filters.resolutionFilter.toString(),
            SearchQuery: filters.searchQuery.toString(),
            SortDirection: sortDirection,
            SortField: sortField,
            ShowLatest: filters.showLatest
        }).then(resp => {                        
            const bySeverityData=[]
            Object.entries(resp.data.Severity).forEach(([key, val]) => bySeverityData.push({
                id: key === "" ? "INFO" : key,
                value: val
            }))

            let byLanguageData=[]            
            Object.entries(resp.data.Language).forEach(([key, val]) => {                
                byLanguageData.push({
                    id: key,
                    label: key,
                    value: val
                })                
            })

            let byLibraryData=[]
            Object.entries(resp.data.Library).forEach(([key, val]) => byLibraryData.push({
                id: key,
                label: key,
                value: val
            }))

            let byProjectData=[]
            Object.entries(resp.data.Project).forEach(([key, val]) => byProjectData.push({
                id: key,
                label: key,
                value: val
            }))


            const byResolutionData=[]
            Object.entries(resp.data.Status).forEach(([key, val]) => byResolutionData.push({
                id: key,
                label: key,
                value: val
            }))            
            

           

            byLanguageData = byLanguageData.sort((a,b)=>b.value - a.value).slice(0, 20)
            byLibraryData = byLibraryData.sort((a,b)=>b.value - a.value).slice(0, 20)
            byProjectData = byProjectData.sort((a,b)=>b.value - a.value).slice(0, 20)

            if (pageNumber===0){
                changeBySeverityData(bySeverityData)
                changeByResolutionData(byResolutionData)    
                changeTotalItemCount(resp.data.Total)
            }            

            changeDiscoveryData(resp.data.Cves)                        
            
            changeByLanguageData(byLanguageData)
            changeByLibraryData(byLibraryData)
            changeByProjectData(byProjectData)

            changeIsLoading(false)
        })        
    }, [pageNumber, filters, sortDirection, sortField])

        
    return <EuiFlexGroup gutterSize="l" style={{marginTop: 25}} alignItems="center" direction="column">            
        <EuiFlexItem>
            {getPageContent(isLoading, totalItemCount, hasFilters(filters))}    
        </EuiFlexItem>
        <FilterBar filters={filters} discardClickHandler={()=>changeFilters(DEFAULT_FILTERS)}/>        
    </EuiFlexGroup>
            

}

export default Library