import React from "react";
import ReactDOM from "react-dom";

import {
    EuiModal,
    EuiModalBody,
    EuiOverlayMask,
  } from '@elastic/eui';
import RingLoader from "react-spinners/RingLoader";

const modalRoot = document.getElementById('modal-root');


export default ({isLoading}) => {
    let content = null
    
    if (isLoading){
        content = <EuiOverlayMask>
            <EuiModal>            
                <EuiModalBody>
                    <div>
                        <RingLoader                            
                            css={{
                                display: "block",
                                margin: "0 auto"
                            }}
                            size={60}
                            loading={isLoading}
                            color="#36D7B7"
                        />
                    </div>                        
                    <div style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            textAlign: "center"
                    }}>
                        <h2>Loading, please wait <br /> (this may take a while)...</h2>
                    </div>                        
                </EuiModalBody>
            </EuiModal>
        </EuiOverlayMask>
    }

    return ReactDOM.createPortal(
        content,
        modalRoot
    )
}


