import React, { useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

import PdfTable from '../PdfWidgets/PdfTable';
import PdfDetailsTable from '../PdfWidgets/PdfDetailsTable';
import LoadingModal from '../CommonWidgets/LoadingModal';
import IconMap from '../../Utils';

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4'
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  subTitle: {
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  subSubTitle: {
    fontSize: 16,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

// to get image, something similar to:
// const imagesData = document.getElementsByTagName('canvas')
// changeBySeverityImage(imagesData[0].toDataURL())
export default ({risk, hosts, resolution, alertData}) => {

    const [isLoading, changeIsLoading] = useState(true)
    
    return <>
    <LoadingModal isLoading={isLoading} />
    <PDFViewer style={{height: window.innerHeight-80, width: window.innerWidth-(window.innerWidth*0.2)}}>
            <Document onRender={()=>setTimeout(() => {
                            changeIsLoading(false)
                        }, 1)}>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.title}>Tutela</Text>
                    <Text style={styles.title}>Compliance Report</Text>                    
                    <Text style={styles.subTitle}>{(new Date()).toGMTString()}</Text>                    
                    <Image 
                        src={{uri: IconMap[`${window.location.hostname}-report`] || IconMap["localhost-report"]}} 
                        style={{
                            width: 250,
                            height: 250,
                            margin: "auto"
                        }}
                    />
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                
                <View style={styles.section} >
                    <Text style={styles.title}>Alerts By Risk</Text>
                    <Image 
                        source={ {uri: risk.Image} }
                        style={{
                            width: 300,
                            height: 300,
                            margin: "auto"
                        }}
                    />
                    <PdfTable data={risk.Data} headers={['Risk', 'Count']}/>
                </View>
            </Page>

            <Page size="A4" style={styles.page}>
                <View style={styles.section} break>
                    <Text style={styles.title}>Alerts By Host</Text>
                    <Image 
                        source={ {uri: hosts.Image} }
                        style={{
                            width: 300,
                            height: 300,
                            margin: "auto"
                        }}
                    />
                    <PdfTable data={hosts.Data} headers={['Host', 'Count']}/>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <View style={styles.section} break>
                    <Text style={styles.title}>Alerts By Status</Text>
                    <Image 
                        source={ {uri: resolution.Image} }
                        style={{
                            width: 300,
                            height: 300,
                            margin: "auto"
                        }}
                    />
                    <PdfTable data={resolution.Data} headers={['Status', 'Count']}/>
                </View>
            </Page>            
            <Page size="A4" style={styles.page}>
                <View style={styles.section} break>
                    <Text style={styles.title}>
                        Alert Details
                    </Text>
                    <PdfDetailsTable data={alertData}/>
                </View>
            </Page>
            </Document>
        </PDFViewer>
        </>    
}