import Axios from "axios";
import React from "react";
import { useEffect } from "react";
import Api from "../../Api";
import { useState } from "react";
import { EuiHealth, EuiHorizontalRule, EuiIcon, EuiInMemoryTable, EuiSpacer, EuiText } from "@elastic/eui";
import ExportAsCSV from "../CommonWidgets/ExportAsCSV";


const IsoTable = () => {

    const [allIsoChecks, changeAllIsoChecks] = useState([])
    const [failingIsoChecks, changeFailingIsoChecks] = useState([])
    const [passingIsoChecks, changePassingIsoChecks] = useState([])

    const [searchQuery, changeSearchQuery] = useState()
    const [filters, changeFilters] = useState([])
    const [filteredData, changeFilteredData] = useState([])

    const search = {
        query: searchQuery,
        box: {
          incremental: true,
          schema: false,
        },
        filters: [
              {
                type: 'is',
                field: 'Resolved',
                name: 'Passing',
                negatedName: 'Failing',
              },
              {
                type: 'field_value_selection',
                field: 'IsoTitle',
                name: 'ISO Check',
                multiSelect: false,
                options: [...failingIsoChecks, ...passingIsoChecks].reduce((acc, { IsoTitle }) => {
                    acc.indexOf(IsoTitle) === -1 && acc.push(IsoTitle)
                    return acc
                },[]).map(( IsoTitle ) => ({
                  value: IsoTitle,
                })),
              },
              {
                type: 'field_value_selection',
                field: 'Stig_id',
                name: 'Tutela Check',
                multiSelect: false,
                options: [...failingIsoChecks, ...passingIsoChecks].reduce((acc, { Stig_id }) => {
                    acc.indexOf(Stig_id) === -1 && acc.push(Stig_id)
                    return acc
                },[]).map(( Stig_id ) => ({
                  value: Stig_id,
                })),
              },
            ],
          onChange: (e)=>{
            changeSearchQuery(e.queryText)
            changeFilters(e.query.ast._clauses)            
          }
      };
    

    const columns = [
        {
          field: 'Hostname',
          name: 'Hostname',
          sortable: true,
          truncateText: false          
        },
        {
          field: 'LoggedInUser',
          name: 'Username',
          sortable: true,
          truncateText: false          
        },
        {
          field: 'IsoTitle',
          name: 'ISO Check',
          truncateText: false,          
        },        
        {
            field: 'Stig_id',
            name: 'Tutela Check',
            truncateText: false,   
            render: (Stig_id) => {
                return <>
                    <a target="_blank" rel="noreferrer" href={`https://outline.cybersift.io/s/support-wiki/doc/windows-security-checks-dobIwOpqCe#h-${Stig_id.toLowerCase()}`}>{Stig_id}</a><br />
                </>;
              }    
          },        
        {
            field: 'Mapped_iso_annex_id',
            name: 'Mapped ISO Annex',
            truncateText: false,
            render: (e) => {
              return <div>{
                    e.split(',').map(annex=><><a target="_blank" rel="noreferrer" href={`https://outline.cybersift.io/s/support-wiki/doc/iso-27001-2gOsvCh4zI#h-${annex.replaceAll(".","").toLowerCase()}`}>{annex}</a><br /></>)
                }</div>;
            }            
        },  
        {
          field: 'Resolved',
          name: 'Status',
          dataType: 'boolean',
          render: (e) => {
            const color = e ? 'success' : 'danger';
            const label = e ? 'Passing' : 'Failing';
            return <EuiHealth color={color}>{label}</EuiHealth>;
          }          
        }
      ];


    useEffect(()=>{
        Axios.get(Api.retrieveIsoFailingChecks).then(resp=>changeFailingIsoChecks(resp.data || []))
        Axios.get(Api.retrieveIsoPassingChecks).then(resp=>changePassingIsoChecks(resp.data || []))
    },[])

    useEffect(()=>{
        changeAllIsoChecks([...failingIsoChecks, ...passingIsoChecks])       
    },[passingIsoChecks, failingIsoChecks])

    useEffect(()=>{
      let results = allIsoChecks.filter(datum=> {
        for (let index = 0; index < filters.length; index++) {
  
          if (filters[index].type == "field"){
            if (datum[filters[index].field] != filters[index].value) {
              return false
            }
          }
  
          if (filters[index].type == "term"){
            if (!Object.values(datum).join(" ").includes(filters[index].value)) {
              return false
            }          
          }        
        }
        return true
      }).map(datum => ({
        ...datum,
        Mapped_iso_annex_id: `"${datum.Mapped_iso_annex_id}"`
      }))

      changeFilteredData(results)
    },[allIsoChecks, filters])


    return <>
    <p>
      <EuiIcon type="questionInCircle"></EuiIcon> TIP: Want to negate your filter? Just prepend a negative sign to your search term! 
        < br />
        <EuiText size="s">
        <em>
          e.g. clicking the "passing" filter will automatically insert "is:Resolved" in the search bar to show you only passing check. To view only FAILING checks use "-is:Resolved"
        </em>
        </EuiText>
    </p>

    <EuiSpacer />

    <EuiInMemoryTable
      search={search}
      searchFormat='text'
      tableCaption="ISO Checks"
      items={allIsoChecks}
      columns={columns}
      pagination={true}      
    />

    <EuiHorizontalRule size="half" />
    <ExportAsCSV data={filteredData} />


    </>
}

export default IsoTable