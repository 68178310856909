import { ResponsiveBarCanvas } from '@nivo/bar'
import React from 'react'

export default (props) => {
    return <ResponsiveBarCanvas
        pixelRatio={1}
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        colors={barData => {
            switch (barData.indexValue) {
                case "CRITICAL":
                    return "#f94144"
                case "HIGH":
                    return "#f3722c"
                case "MEDIUM":
                    return "#f8961e"
                case "LOW":
                    return "#f9c74f"
                case "INFO":
                    return "#577590"
                default:
                    return "wheat"
            }
        }}
        data={props.data}
        animate={true}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Severity',
            legendPosition: 'middle',
            legendOffset: 32
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        enableGridX={false}
        enableGridY={false}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}     
        theme={props.theme}               
        onClick={props.clickHandler}  
    />
}