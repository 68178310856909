import React from 'react'

const ViewContext = React.createContext({
    changeView: ()=>console.log("Context Not Init")
  });

const UserContext = React.createContext({
  isAdminUser: false,
  changeIsAdminUser: () => null
});

export {ViewContext, UserContext}