import { EuiBasicTable, EuiButton, EuiFlexGroup, EuiFlexItem, EuiHealth, EuiSearchBar, EuiSpacer, EuiSuperSelect } from '@elastic/eui';
import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react'
import Api from '../../Api';
import DetailsFlyout from './DetailsFlyout';
import NotesFlyout from '../CommonWidgets/NotesFlyout';


const LibVulnTable = ({
    data, 
    pageIndex = 0, 
    totalItemCount = 0, 
    setPageIndex = ()=>{}, 
    handleSearchChange = ()=>{}, 
    initialSearch=false, 
    sortInfo = {
        sortField: "Project",
        sortDirection: "asc",
        handleSortChange: (sortField, sortDirection) => {},    
    },
    searchFilterData={},
    searchFilterHandler
 }) => {
     
    const [TableData, changeTableData] = useState([])
    const [notesFlyoutVisible, changeNotesFlyoutVisible]=useState(false)
    const [detailsFlyoutVisible, changeDetailsFlyoutVisible]=useState(false)
    const [selectionValues, setSelectionValues] = useState([])
    const [selectedCveDetails, changeSelectedCveDetails] = useState({}) 
    const [selectedNoteDetails, changeSelectedNoteDetails]=useState({
        library: null,
        project: null,
        cve: null
    })
    const [sortField, setSortField] = useState(sortInfo.sortField);
    const [sortDirection, setSortDirection] = useState(sortInfo.sortDirection);
    const [searchFilterInfo, changeSearchFilterInfo] = useState({
        "Severity": {
            options: [{value:"-", inputDisplay: "Filter By Severity", disabled: true}],
            valueOfSelected: "-"
        },
        "Project":{
            options: [{value:"-", inputDisplay: "Filter By Project", disabled: true}],
            valueOfSelected: "-"
        },
        "Library":{
            options: [{value:"-", inputDisplay: "Filter By Library", disabled: true}],
            valueOfSelected: "-"
        }, 
        "Language":{
            options: [{value:"-", inputDisplay: "Filter By Language", disabled: true}],
            valueOfSelected: "-"
        }
    })

    useEffect(()=>{
        let result={}
        Object.keys(searchFilterData).forEach(k=>{
            result[k]={
                options: [{value:"-", inputDisplay: "Filter By "+k, disabled: true}],
                valueOfSelected: "-"
            }
            searchFilterData[k].forEach(entry=>result[k]["options"].push({value:entry.id, inputDisplay: entry.id}))            
            result[k].handleSearchFilterChange = (value)=>searchFilterHandler(value,k.toLowerCase()+"Filter")            
        })
        changeSearchFilterInfo(result)
    },[searchFilterData, searchFilterHandler])

    const handleUpdateNote = noteText => {       

        Axios.post(Api.updateLibraryNote, {
            Library: selectedNoteDetails.library,
            Project: selectedNoteDetails.project,
            CveID: selectedNoteDetails.cve,
            Note: noteText
        })

        const tableRowToUpdate = TableData.findIndex(elem => (elem.Project === selectedNoteDetails.project && 
            elem.Library === selectedNoteDetails.library && 
            elem.CveID=== selectedNoteDetails.cve ))

        
        const tempTable = [...TableData]
        tempTable[tableRowToUpdate].Notes = noteText
        changeTableData(tempTable)
        

        changeSelectedNoteDetails({
            project: null,
            library: null,
            cve: null
        })

        changeNotesFlyoutVisible(false)
    }
    
    const handleToggleResolved = newNote => () => {       

        
        Axios.post(Api.updateLibraryResolved, newNote).then( () =>{
            const tableRowToUpdate = TableData.findIndex(elem => (elem.Project === newNote.Project && 
                elem.Library === newNote.Library && 
                elem.CveID=== newNote.CveID ))
            
            const tempTable = [...TableData]
            if (tempTable[tableRowToUpdate]){
                tempTable[tableRowToUpdate].Resolved = newNote.Resolved
            }
    
            changeTableData(tempTable)
        })
    
        
    }

    useEffect(()=>{

        changeTableData(data)

    },[data])

    const tableSelectionValue = {
        onSelectionChange: (selection) => {
            setSelectionValues(selection)
        }        
      };

    const handleMassNote = () => {

        let noteText = window.prompt("Insert New Note:")

        selectionValues.forEach(item => {
            Axios.post(Api.updateLibraryNote, {
                Project: item.Project,
                Library: item.Library,
                CveID: item.CveID,
                Note: noteText
            })
    
            const tableRowToUpdate = TableData.findIndex(elem => (elem.Project === item.Project && 
                elem.Library === item.Library && 
                elem.CveID=== item.CveID ))
    
            
            const tempTable = [...TableData]
            tempTable[tableRowToUpdate].Notes = noteText
            changeTableData(tempTable)
            
                
        })
    }

    const handleMassResolve = () => {
        selectionValues.forEach(item => handleToggleResolved({
            Project: item.Project, 
            Library: item.Library,
            CveID: item.CveID,
            Resolved: true
        })())
    }

    const handleMassPending = () => {
        selectionValues.forEach(item => handleToggleResolved({
            Project: item.Project, 
            Library: item.Library,
            CveID: item.CveID,
            Resolved: false
        })())
    }

    const onTableChange = ({ page = {}, sort = {} }) => {
        const { index: pageIndex } = page;     
        setPageIndex(pageIndex);
    
        const { field: sortField, direction: sortDirection } = sort;
        setSortField(sortField);
        setSortDirection(sortDirection);
    };

    useEffect(()=> {
        sortInfo.handleSortChange(sortField, sortDirection)
    }, [sortField, sortDirection, sortInfo])


    return <Fragment>
            <EuiFlexGroup gutterSize="m">
                <EuiFlexItem>
                    <EuiSearchBar
                        fullWidth 
                        placeholder="Free Search..." 
                        onChange={handleSearchChange}
                        defaultQuery={initialSearch}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup gutterSize="m">
                {Object.keys(searchFilterInfo).map(searchFilterKey=><EuiFlexItem>
                    <EuiSuperSelect
                        options={searchFilterInfo[searchFilterKey].options}
                        valueOfSelected={searchFilterInfo[searchFilterKey].valueOfSelected}
                        onChange={(value)=>searchFilterInfo[searchFilterKey].handleSearchFilterChange(value)}
                    />
                </EuiFlexItem>)}                
            </EuiFlexGroup>
            {selectionValues.length > 0 ? <>
                <EuiButton fill color="primary" onClick={handleMassResolve}>
                    Mark as Resolved
                </EuiButton>
                <EuiButton fill color="primary" style={{marginLeft: 10}} onClick={handleMassPending}>
                    Mark as Pending
                </EuiButton>
                <EuiButton fill color="primary" style={{marginLeft: 10}} onClick={handleMassNote}>
                    Insert Note
                </EuiButton>
                <EuiSpacer />
            </>
                :null}
            <EuiBasicTable
                itemId="id"
                items={TableData}
                onChange={onTableChange}                
                columns={[
                    {
                        width: "100px",
                        field: 'Resolved',
                        name: 'Resolved',
                        dataType: 'boolean',
                        render: resolved => {
                            const color = resolved ? 'success' : 'danger';
                            const label = resolved ? 'Resolved' : 'Pending';
                            return <EuiHealth color={color}>{label}</EuiHealth>;
                        }
                    },
                    {
                        field: 'Project',
                        name: 'Project',
                        width: "150px",
                        sortable: true,
                    },
                    {
                        field: 'Language',
                        name: 'Language',       
                        sortable: true,                 
                    },
                    {
                        field: 'Library',
                        name: 'Library',
                        
                        sortable: true,
                    },
                    {
                        field: 'Version',
                        name: 'Version',       
                        sortable: true,                 
                    },
                    {
                        field: 'Severity',
                        name: 'Severity',
                        sortable: true,
                    },
                    {
                        field: 'CveID',
                        name: 'CVE',
                        sortable: true,
                    },
                    {
                        field: 'CveDescription',
                        name: 'Description',
                        width: "30%",
                    },
                    {
                        actions: [{
                            name: "View Notes",
                            description: "View Notes",
                            render: (item) => {
                                return <EuiButton
                                        style={{margin: 5}}
                                        iconSide="left" 
                                        iconType="documentEdit" 
                                        onClick={()=>{
                                            changeSelectedNoteDetails({
                                                project: item.Project, 
                                                library: item.Library,
                                                cve: item.CveID,
                                                text: item.Notes})
                                            changeNotesFlyoutVisible(true)
                                        }} 
                                    >
                                        View Notes
                                </EuiButton>
                              }
                        },{
                            name: "Toggle Resolved",
                            description: "Toggle Resolved",
                            render: item => <EuiButton 
                                        iconSide="left"
                                        iconType="checkInCircleFilled"
                                        style={{margin: 5}}
                                        onClick={handleToggleResolved({
                                            project: item.Project, 
                                            library: item.Library,
                                            cve: item.CveID,
                                            Resolved: !item.Resolved
                                        })}
                                    >
                                        Toggle Resolved
                                    </EuiButton>
                        },{
                            name: "Additional",
                            description: "Additional Info",
                            render: item => {
                                return <EuiButton
                                        iconSide="left"
                                        iconType="expand"
                                        style={{margin: 5}}
                                        onClick={() => {                                            
                                            changeSelectedCveDetails({
                                                ...item.CveDetails
                                            })
                                            changeDetailsFlyoutVisible(true)                                                                                            
                                        }}
                                    >
                                    Additional Details
                                  </EuiButton>;
                              }
                        }]
                    }
                ]}
                pagination={{
                    pageIndex: pageIndex,
                    pageSize: 50,
                    totalItemCount:totalItemCount,
                    hidePerPageOptions: true
                }}
                isSelectable={true}
                selection={tableSelectionValue}
                sorting = {{
                    sort: {
                        field: sortField,
                        direction: sortDirection,
                    }
                }}
            />
            {notesFlyoutVisible ? <NotesFlyout handleVisibility={changeNotesFlyoutVisible} noteText={selectedNoteDetails.text} handleUpdateNote={handleUpdateNote} />: null}
            {detailsFlyoutVisible ? <DetailsFlyout handleVisibility={changeDetailsFlyoutVisible} cveDetails={selectedCveDetails} serviceDetails={{}} />: null}
        </Fragment>
}

export default LibVulnTable