import React, { useEffect, useState } from "react"
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiTab,
    EuiTabs,
  } from '@elastic/eui';

import ComplianceTableV2 from "./ComplianceWidgets/ComplianceTableV2";
import IsoTable from "./ComplianceWidgets/IsoTable";
import EolTable from "./ComplianceWidgets/EolTable";
import CloudComplianceTable from "./ComplianceWidgets/CloudComplianceTable";

const Compliance = () => {

    const [selectedTabId, changeSelectedTabId] = useState('best-practice-tab')

    const tabs = [
        {
          id: 'best-practice-tab',
          name: 'Best Practices',
          prepend: <EuiIcon type="inspect"/>
        },{
            id: 'cloud-compliance-tab',
            name: 'Cloud Compliance',
            prepend: <EuiIcon type="inspect"/>
        },{
            id: 'iso-tab',
            name: 'ISO 27001',
            prepend: <EuiIcon type="inspect"/>
        },{
            id: 'eol-tab',
            name: 'End Of Life Software',
            prepend: <EuiIcon type="inspect"/>
        }
    ]


    const getTabContent = () => {
        switch (selectedTabId) {
            case "best-practice-tab":
                return <ComplianceTableV2 />     
            case "iso-tab":
                return <IsoTable />
            case "eol-tab":
                return <EolTable />
            case "cloud-compliance-tab":
                return <CloudComplianceTable />
            default:
                break;
        }
    }


    return <>
        <EuiTabs>
            {tabs.map((tab,index)=><EuiTab
                key={index}
                onClick={() => changeSelectedTabId(tab.id)}
                isSelected={tab.id === selectedTabId}
                prepend={tab.prepend}
                append={tab.append}
            >
                {tab.name}
            </EuiTab>)}
        </EuiTabs>
        <EuiFlexGroup gutterSize="l" style={{marginTop: 25}} alignItems="center" direction="column">            
            <EuiFlexItem>
                {getTabContent()}
            </EuiFlexItem>
        </EuiFlexGroup>
    </>
}

export default Compliance