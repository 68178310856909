import Axios from "axios";
import React from "react";
import { useEffect } from "react";
import Api from "../../Api";
import { useState } from "react";
import { EuiHealth, EuiHorizontalRule, EuiInMemoryTable } from "@elastic/eui";
import ExportAsCSV from "../CommonWidgets/ExportAsCSV";


const EolTable = () => {

    const [allEolChecks, changeAllEolChecks] = useState([])
    const [searchQuery, changeSearchQuery] = useState()
    const [filters, changeFilters] = useState([])
    const [filteredData, changeFilteredData] = useState([])

    useEffect(()=>{
      Axios.get(Api.retrieveEolChecks).then(resp=> resp.data && changeAllEolChecks(resp.data))
    },[])

    useEffect(()=>{
      let results = allEolChecks.filter(datum=> {
        for (let index = 0; index < filters.length; index++) {
  
          if (filters[index].type == "field"){
            if (datum[filters[index].field] != filters[index].value) {
              return false
            }
          }
  
          if (filters[index].type == "term"){
            if (!Object.values(datum).join(" ").includes(filters[index].value)) {
              return false
            }          
          }        
        }
        return true
      }).map(datum => ({
        ...datum,
        Details: `"${datum.Details}"`
      }))

      changeFilteredData(results)
    },[allEolChecks, filters])

    const search = {
        query: searchQuery,
        box: {
          incremental: true,
          schema: false,
        },
        filters: [
              {
                type: 'field_value_selection',
                field: 'Status',
                name: 'Status',
                multiSelect: false,
                options: allEolChecks.reduce((acc, { Status }) => {
                    acc.indexOf(Status) === -1 && acc.push(Status)
                    return acc
                },[]).map(( Status ) => ({
                  value: Status,
                })),
              },
              {
                type: 'field_value_selection',
                field: 'Hostname',
                name: 'Hostname',
                multiSelect: false,
                options: allEolChecks.reduce((acc, { Hostname }) => {
                    acc.indexOf(Hostname) === -1 && acc.push(Hostname)
                    return acc
                },[]).map(( Hostname ) => ({
                  value: Hostname,
                })),
              },
              {
                type: 'field_value_selection',
                field: 'Product',
                name: 'Product',
                multiSelect: false,
                options: allEolChecks.reduce((acc, { Product }) => {
                    acc.indexOf(Product) === -1 && acc.push(Product)
                    return acc
                },[]).map(( Product ) => ({
                  value: Product,
                })),
              },
            ],
          onChange: (e)=>{
            changeSearchQuery(e.queryText)
            changeFilters(e.query.ast._clauses)            
          }
      };
    

    const columns = [
        {
          field: 'Hostname',
          name: 'Hostname',
          sortable: true,
          truncateText: false          
        },
        {
          field: 'Product',
          name: 'Product',
          truncateText: false,          
        },        
        {
          field: 'ProductClass',
          name: 'Product Class',
          truncateText: false,          
        },  
        {
          field: 'Details',
          name: 'Details',
          truncateText: false,          
        },
        {
          field: 'Status',
          name: 'Status',
          dataType: 'boolean',
          render: (e) => {
            let color =  ''
            
            switch (e) {
              case "OK":
                color = 'success'
                break;
              case "EOL":
                color = 'danger'
                break;
              default:
                color='warning'
                break;
            }

            return <EuiHealth color={color}>{e}</EuiHealth>;
          }          
        }
      ];


    


    return <>
    <EuiInMemoryTable
      search={search}
      searchFormat='text'
      tableCaption="EndOfLife Checks"
      items={allEolChecks}
      columns={columns}
      pagination={true}      
    />

    <EuiHorizontalRule size="half" />
    <ExportAsCSV data={filteredData} />

    </>
}

export default EolTable