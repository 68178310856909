import { EuiButton } from '@elastic/eui'
import Axios from 'axios'
import React, { useState } from 'react'
import Api from '../../Api'
import LoadingModal from './LoadingModal'
import { Description } from '@material-ui/icons'

const ExportAsCSV = ({dataView, filters, data})=>{

    const [isLoading, changeIsLoading] = useState(false)

    const generateCSV = (tableData) => {
        if (!tableData){
            alert("Could not export!")
            changeIsLoading(false); 
            return
        }

        if (tableData.length<=0){
            alert("Could not export!")
            changeIsLoading(false); 
            return
        }

        const headers = Object.keys(tableData[0])        
        const rows = tableData.map(datum=>
            Object.values(datum)
        );

                
        let csvContent = headers.join(",")+"\n"
            + rows.map(e => e.join(",")).join("\n");

        var exportedFilename = 'export.csv';

        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

        changeIsLoading(false); 
    }


    const exportToCSV = () => {
        changeIsLoading(true)
        switch (dataView) {
            case "CLOUD_ASSETS":
                // remove item.AgentsInstalled from data
                data = data.map(item => {
                    delete item.AgentsInstalled
                    delete item.Id
                    item = {
                        ...item, 
                        Tags: item.Tags.replaceAll('\n', " | ")
                    }
                    return item
                })
                generateCSV(data)
                break
            case "FLASHLIGHT":
                const tableData = []

                    data.forEach(host => {               

                        tableData.push({
                            // id: `${cve.Address}:${cve.CveID}:${cve.Port}:${cve.Service.Product}`,
                            Host: host.Source,
                            Monitored: host.Monitored,
                        })
                        
                    });
            
                generateCSV(tableData)
                break
            case "CLOUD_COMPLIANCE":                          
                Axios.post(Api.retrieveCloudComplianceResults, {
                    ...filters
                }).then(resp => {                
        
                    console.log("got report data...")
                    if (resp.data && resp.data.Results){                    
        
                        const tableData = []

                        resp.data.Results.forEach(result => {               

                        tableData.push({
                            Cloud: result.FriendlyName,
                            Status: result.Status,
                            Resource: result.Resource,
                            Title: result.Title,                            
                            Reason: `"${result.Reasons}"`,
                            Description: `"${result.Description}"`,
                        })
                        
                    });
            
                    generateCSV(tableData)
        
                    }                   
                })        
                break
            case "COMPLIANCE":
                Axios.post(Api.retrieveComplianceAlerts, {
                    PageIndex: 0,
                    PageSize: -1,
                    Filters: filters
                }).then(resp => {                
        
                    console.log("got report data...")
                    if (resp.data && resp.data.ComplianceAlerts){                    
        
                        const tableData = []

                        resp.data.ComplianceAlerts.forEach(alert => {               

                        tableData.push({
                            HostAddress: alert.Host.Address,
                            HostName: alert.Host.Hostname,
                            Severity: alert.Severity === "" ? "INFO" : alert.Severity,
                            AlertID: alert.StigID,
                            Resolved: alert.Resolved,
                            AlertTitle: alert.Title,
                            AlertDescription: '"'+alert.Description.replace('\r',' ').replace('\n','  ').replace(',',' ')+'"',
                            Notes: '"'+alert.Notes.replace('\r',' ').replace('\n','  ').replace(',',' ')+'"'                            
                        })
                        
                    });
            
                    generateCSV(tableData)
        
                    }                   
                })        
                break
            case "VULN":   
                Axios.post(Api.retrieveCVEs_V2+"/-1", {
                    Scan: filters.scanFilter.toString(),
                    Port: filters.portFilter.toString(),
                    Host: filters.addressFilter.toString(),
                    Severity: filters.severityFilter.toString(),
                    Status: filters.resolutionFilter.toString(),
                    SearchQuery: filters.searchQuery.toString(),
                    HostGroup: filters.hostgroupFilter.toString(),
                    Exploitable: filters.exploitFilter.toString(),
                    DetectionType: filters.detectionType.toString()
                }).then(resp=>{

                    const discoveryData = resp.data.Cves
                    const tableData = []

                    discoveryData.forEach(cve => {               

                        tableData.push({
                            // id: `${cve.Address}:${cve.CveID}:${cve.Port}:${cve.Service.Product}`,
                            Address: cve.Address,
                            Port: cve.Port,
                            Service: cve.Service.Name,
                            Product: cve.Package.Name === "" ? cve.Service.Product : cve.Package.Name,
                            Version: cve.Package.Version === "" ? cve.Service.Version : cve.Package.Version,
                            Severity: cve.Cve.Severity === "" ? "INFO" : cve.Cve.Severity,
                            // ServiceDetails: cve.Service,
                            CveID: cve.CveID,
                            CveDetails: `https://nvd.nist.gov/vuln/detail/${cve.CveID}`,
                            Resolved: cve.Resolved,
                            Notes: '"'+cve.Notes.replace('\r',' ').replace('\n','  ').replace(',',' ')+'"',
                            ExploitDb: cve.ExploitDb,
                            CveDescription: '"'+cve.Cve.Description.replace('\r',' ').replace('\n','  ').replace(',',' ')+'"',
                        })
                        
                    });
            
                    generateCSV(tableData)
                    
                })             
                break;                            
            case "network":
                Axios.post(Api.retrieveNetworkServiceAssets+"/-1", filters).then(resp=>{
                    const tableData = []
                    const assetData = resp.data.Hosts
                    assetData.forEach(host => {              

                        const hostAddress = host.Address
                        const hostName = host.Hostname

                        host.Services.filter(service => {
                            if (filters.showTcpWrapped){
                                 return true
                            }
                            return service.Name !== "tcpwrapped"
                        }).forEach(service => tableData.push({      
                            Unix_Timestamp: service.Timestamp,    
                            ID: service.ID,      
                            Address: hostAddress,
                            Hostname: hostName,
                            OS: service.Os,
                            Port: service.PortNumber,
                            ServiceName: service.Name,
                            Product: service.Product==="" ? "Unknown" : service.Product,
                            Cpe: service.Cpe
                        }))   
            
                    });    
            
                    generateCSV(tableData)
                })             
                break;    
    
            case "software":
                Axios.post(Api.retrieveSoftwareAssets+"/-1", {
                    ...filters
                }).then(resp=>{
                    const tableData = []
                    const assetData = resp.data.Hosts
                    assetData.forEach(host => {              

                        const hostAddress = host.Address
                        const hostName = host.Hostname

                        host.Packages.forEach(pkg => {
                            const {ID, HostID, UserID, ScanCounter, EntityType, ...tablePkg} = pkg
                            tableData.push({          
                            Address: hostAddress,
                            Hostname: hostName,
                            ...tablePkg,
                            Version: pkg.Version.replace("\r", "").replace("\n", ""),                            
                            })
                        }) 
            
                    });    
            
                    generateCSV(tableData)
                })             
                break;    

            case "networkAssets":
                Axios.post(Api.retrieveNetworkAssets+`/-1`, {
                    ...filters
                  }).then(resp => {
          
                    let entries = resp.data.Hosts
                    generateCSV(entries)
                  })            
                break;

            case "hostAssets":
                Axios.get(Api.retrieveHostAssets).then(resp=>{
                    generateCSV(resp.data)                    
                })                       
                break;                

            case "ASSETS":
                Axios.post(Api.retrieveAssets+"/-1", {
                    SearchQuery: filters.searchQuery.toString(),                    
                }).then(resp=>{
                    const tableData = []
                    const assetData = resp.data.Hosts
                    assetData.forEach(host => {              

                        const hostAddress = host.Address
            
                        host.Services.filter(service => {
                            if (filters.showTcpWrapped){
                                 return true
                            }
                            return service.Name !== "tcpwrapped"
                        }).forEach(service => tableData.push({          
                            ID: service.ID,      
                            Address: hostAddress,
                            OS: service.Os,
                            Port: service.PortNumber,
                            ServiceName: service.Name,
                            Product: service.Product==="" ? "Unknown" : service.Product,
                            Cpe: service.Cpe
                        }))        
                        
                        host.Packages.forEach(pkg => tableData.push({          
                            ID: pkg.ID,      
                            Address: hostAddress,
                            OS: host.OS,
                            Port: "n/a",
                            ServiceName: pkg.Name,
                            Product: pkg.Version,
                            Cpe: "TODO"
                        }))
            
                    });    
            
                    generateCSV(tableData)
                })             
                break;            
            default:
                generateCSV(data)
                break;
        }                                   

    }

    return <>
        { isLoading ? <LoadingModal isLoading={isLoading} /> : null }
        <EuiButton
            style={{margin:10}}
            onClick={exportToCSV}
            fill
            color="primary"
        >
            Export To CSV
        </EuiButton>
    </>

}

export default ExportAsCSV