import React, { Fragment, useEffect, useState } from 'react'
import { EuiBetaBadge, 
    EuiFlexGroup, 
    EuiFlexItem, 
    EuiIcon, 
    EuiStat, 
    EuiTextColor,
    EuiDescriptionList,
    EuiInMemoryTable,
    EuiPageContent,
    EuiPageContentBody,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiSpacer,
    EuiTitle,
    EuiToolTip,
  } from '@elastic/eui';
import Axios from 'axios';
import Api from '../Api';

import LoadingModal from './CommonWidgets/LoadingModal';
import StackedProgress from './CommonWidgets/StackedProgress';

const severities = [ "critical", "high", "medium", "low" ];

const EntityDash = () => {

    const [isLoading, changeIsLoading] = useState(true)
    const [TableData, changeTableData] = useState([])    

    
    const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState({});
  

    const populateData = () => {     

        const fetchData = async () =>  {
            // Make two HTTP calls using Axios
            const vulnRequest = Axios.get(Api.getEntityVulns);
            const complianceRequest = Axios.get(Api.getEntityCompliance);
            const metadataRequest = Axios.get(Api.getEntityMetadata);

            // Wait for both requests to complete using Promise.all
            const [vulnResponse, complianceResponse, metadataResponse] = await Promise.all([vulnRequest, complianceRequest, metadataRequest]);

            // Access the data from the responses
            const vulnData = vulnResponse.data || [];
            const complianceData = complianceResponse.data || [];
            const metadata = metadataResponse.data || [];
            const riskScores = {};
            
            const results = {}

            metadata.forEach(datum => {
                results[datum.Entity] = {
                    "EntityType": datum.EntityType,
                    "Summary": {
                        "Vulns": {},
                        "Compliance": {}
                    }
                }
            })
            
            vulnData.forEach(datum => {
                results[datum.Entity] && results[datum.Entity]["Summary"]["Vulns"] ? results[datum.Entity]["Summary"]["Vulns"][datum.Severity.toLowerCase()] = datum.Count : results[datum.Entity]  = {
                    "Summary": {
                        ...(results[datum.Entity] && results[datum.Entity]["Summary"] ? results[datum.Entity]["Summary"] : {}),
                        "Vulns": {
                            [datum.Severity.toLowerCase()]: datum.Count
                        }
                    }
                }
            });

            complianceData.forEach(datum => {                
                results[datum.Entity] && results[datum.Entity]["Summary"]["Compliance"] ? results[datum.Entity]["Summary"]["Compliance"][datum.Severity.toLowerCase()] = datum.Count : results[datum.Entity]  = {                    
                    ...results[datum.Entity],
                    "Summary": {
                        ...(results[datum.Entity] && results[datum.Entity]["Summary"] ? results[datum.Entity]["Summary"] : {}),
                        "Compliance": {
                            [datum.Severity.toLowerCase()]: datum.Count
                        }
                    }
                }
            });

            let tempData = Object.keys(results).map(k => ({
                "Entity": k,
                "RiskScore": 0,
                ...results[k]
            }))

            let tableData = tempData.map(datum => {
                let vulnScore = 0
                let complianceScore = 0

                for(let idx = 0; idx < severities.length; idx++){
                    if (datum.Summary.Vulns && datum.Summary.Vulns[severities[idx]]){
                        switch (severities[idx]) {
                            case "critical":
                                vulnScore = 1;
                                break;                        
                            case "high":
                                vulnScore = 0.8;
                                break;
                            case "medium":
                                vulnScore = 0.5;
                                break;                        
                            case "low":
                                vulnScore = 0.3;
                                break;
                            default:
                                break;
                        }
                        break
                    }
                }

                for(let idx = 0; idx < severities.length; idx++){
                    if (datum.Summary.Compliance && datum.Summary.Compliance[severities[idx]]){
                        switch (severities[idx]) {
                            case "critical":
                                complianceScore = 1;
                                break;                        
                            case "high":
                                complianceScore = 0.8;
                                break;
                            case "medium":
                                complianceScore = 0.5;
                                break;                        
                            case "low":
                                complianceScore = 0.3;
                                break;
                            default:
                                break;
                        }
                        break
                    }
                }
                
                let score = 0.0

                if (complianceScore <= 0.5 && complianceScore > 0.0 && vulnScore <= 0.5 && vulnScore > 0.0) {
                    score = 40.0
                }
            
                if (vulnScore <= 0.5 && complianceScore > 0.5) {
                    score = 65.0
                }
            
                if (vulnScore > 0.5 && complianceScore <= 0.5) {
                    score = 80.0
                }
            
                if (vulnScore > 0.5 && complianceScore > 0.5) {
                    score = 95.0
                }
            


                let color = "success"
                let icon = "error"
                let description = ""

                if (score < 1.0){
                    color = "success"
                    icon = "securitySignalResolved"
                    description = "none"
                }
                else if (score < 45.0){
                    color = "primary"
                    icon = "securitySignalResolved"
                    description = "low"
                } else if (score < 75.0){
                    color = "warning"
                    icon = "securitySignalDetected"
                    description = "medium"
                } else {
                    color = "danger"
                    icon = "indexClose"
                    description = "high"
                }

                return {
                    ...datum,
                    RiskScore: {
                        score, 
                        color, 
                        icon,
                        description
                    }
                }
            })

            console.log(tableData)
            changeTableData(tableData)
            changeIsLoading(false)
        }
        fetchData()
    }

    

    const toggleDetails = (item) => {
        const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };
        if (itemIdToExpandedRowMapValues[item.Entity]) {
          delete itemIdToExpandedRowMapValues[item.Entity];
        } else {
          const listItems = [
            {
                title: 'Entity',
                description: `${item.Entity}`,
            },
            {
                title: 'Type',
                description: `Host`, //TODO - make variable
            }
          ];
          itemIdToExpandedRowMapValues[item.ID] = (
            <EuiDescriptionList listItems={listItems} />
          );
        }
        setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
      };

    useEffect(()=>{
        populateData()
    }, [])

    
    return <Fragment>
        <EuiPageHeader>
            <EuiPageHeaderSection>
                <EuiSpacer />
                <EuiFlexGroup direction="row">
                    <EuiFlexItem grow={5}>
                        <EuiTitle size="l">
                            <h1>Entity Dashboard</h1> 
                        </EuiTitle>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiBetaBadge label="BETA" tooltipContent="Fully functional, currently improving the UX" style={{backgroundColor: "darkcyan"}}  />
                    </EuiFlexItem>
                </EuiFlexGroup>                            
            </EuiPageHeaderSection>
            </EuiPageHeader>
            <EuiPageContent>
                <EuiPageContentBody>
                <LoadingModal isLoading={isLoading} />
                
                <EuiSpacer />      
                <EuiInMemoryTable
                    itemID='Entity'
                    items={TableData}
                    search={{
                        box: {
                        incremental: true,
                        schema: true,
                        },
                        filters: [
                            {
                              type: 'field_value_selection',
                              field: 'EntityType',
                              name: 'Entity Type',
                              multiSelect: true,
                              options: TableData.map( datum => datum.EntityType ).filter(( value, index, array ) => array.indexOf( value ) === index ).map( datum => ({
                                value: datum,
                              }))
                            },
                          ],
                    }}
                    columns={[
                       {
                            field: 'Entity',
                            name: 'Entity',
                        },
                        {
                            field: 'EntityType',
                            name: 'Type',
                            width: "50px",
                            render: (item) => {
                                return <>
                                    {item==="container" && <EuiToolTip content="Container"><EuiIcon color="primary" type="layers"></EuiIcon></EuiToolTip>}
                                    {item==="host_agent" && <EuiToolTip content="Host Agent(Installed Software)"><EuiIcon color="primary" type="compute"></EuiIcon></EuiToolTip>}
                                    {item==="network_scan" && <EuiToolTip content="Network Scan"><EuiIcon color="primary" type="indexMapping"></EuiIcon></EuiToolTip>} 
                                    {item==="application" && <EuiToolTip content="Application (Library Dependancy)"><EuiIcon color="primary" type="database"></EuiIcon></EuiToolTip>}
                                </>                            
                            }
                        },
                        {
                            field: 'RiskScore',
                            name: 'Risk Score',
                            render: riskScore => {
                                return <EuiStat
                                titleSize='s'
                                title={riskScore.score.toFixed(1)+"%"}
                                textAlign="left"
                                titleColor={riskScore.color}
                                description={
                                  <EuiTextColor color={riskScore.color}>
                                    
                                      <EuiIcon type={riskScore.icon} color={riskScore.color} /> {riskScore.description} 
                                    
                                  </EuiTextColor>
                                }
                              >
                              </EuiStat>
                            }
                        },
                        {
                            field: 'Summary',
                            name: 'Summary',
                            width: "40%",
                            render: summary => {   
                                return <EuiFlexGroup direction='column' gutterSize='m'>

                                    <EuiFlexGroup direction='row' gutterSize='xs' style={{margin: 5}}>
                                        <EuiFlexItem grow={3}>
                                            Vulnerabilities: 
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={10}>
                                            <StackedProgress 
                                                low={summary["Vulns"] && summary["Vulns"]["low"] ? summary["Vulns"]["low"] : 0} 
                                                medium={summary["Vulns"] && summary["Vulns"]["medium"] ? summary["Vulns"]["medium"] : 0} 
                                                high={summary["Vulns"] && summary["Vulns"]["high"] ? summary["Vulns"]["high"] : 0}  
                                                critical={summary["Vulns"] && summary["Vulns"]["critical"] ? summary["Vulns"]["critical"] : 0}  
                                            />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>

                                    <EuiFlexGroup direction='row' gutterSize='xs' style={{margin: 5}}>
                                        <EuiFlexItem grow={3} >
                                            Compliance: 
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={10}>
                                        <StackedProgress 
                                                low={summary["Compliance"] && summary["Compliance"]["low"] ? summary["Compliance"]["low"] : 0} 
                                                medium={summary["Compliance"] && summary["Compliance"]["medium"] ? summary["Compliance"]["medium"] : 0} 
                                                high={summary["Compliance"] && summary["Compliance"]["high"] ? summary["Compliance"]["high"] : 0}  
                                            />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                    
                                    
                                </EuiFlexGroup>
                            }
                        }
                    ]}
                    pagination={true}
                    sorting={true}
                    itemId="Entity"
                    itemIdToExpandedRowMap={itemIdToExpandedRowMap}
                />        
                <EuiSpacer />            
                
                                
                </EuiPageContentBody>                            
            </EuiPageContent>        
           
    </Fragment>
}

export default EntityDash