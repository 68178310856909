import React, { Fragment, useContext, useEffect, useState } from 'react'
import {
    EuiPageContent,
    EuiPageContentBody,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiTitle,
    EuiFieldPassword,
    EuiSpacer, EuiFieldText, EuiIcon, EuiButton, EuiHorizontalRule, EuiFlexGroup, EuiFlexItem, EuiLink
} from '@elastic/eui';
import Axios from 'axios';
import API from '../Api';
import {ViewContext} from '../Context';
import Api from '../Api';

const Login = ({handleSuccessfulLogin}) => {

    const [username, changeUsername] = useState()
    const [password, changePassword] = useState()
    const [tenant, changeTenant] = useState()
    const {changeView} = useContext(ViewContext)

    const handleLogin = () => {

        // this "trick" is here to accomodate named users in on-prem environments not needing to enter "admin" as a tenant
        let actualTenant = (window.location.hostname === "sparkasse.tutela.cybersift.io" && username !== "admin" && !tenant) ? "admin" : tenant

        Axios.post(API.login, {
            "Username": username,
            "Password": password,
            "Tenant": actualTenant
        }).then(resp => {            
            if (handleSuccessfulLogin){
                handleSuccessfulLogin(username)
            }     
        }).catch( _ => alert("Login Failed!"))
        
    }

    useEffect(()=> {
            Axios.post(Api.checkLogin).then(resp => {
            if (resp.status === 200) {
                changeView({
                    viewName: "LANDING"
                }) 
            }
        })
    }, [changeView])
    

    return <Fragment>
        <EuiPageHeader>
            <EuiPageHeaderSection>
                <EuiTitle size="l">
                    <h1>User Login</h1>
                </EuiTitle>
            </EuiPageHeaderSection>
        </EuiPageHeader>
        <EuiPageContent>
            <EuiPageContentBody style={{ marginLeft: "auto", marginRight: "auto" }}>
                <EuiFieldText                    
                    placeholder="Username"
                    prepend={
                        <EuiIcon type="user" href="#" />
                    }
                    value={username}
                    onChange={e => changeUsername(e.target.value)}
                />
                <EuiSpacer />
                <EuiFieldPassword
                    placeholder="Password"
                    value={password}
                    onChange={e => changePassword(e.target.value)}
                    aria-label="Password"
                />
                <EuiSpacer />
                <EuiFieldText                    
                    placeholder="Tenant (optional)"
                    prepend={
                        <EuiIcon type="users" href="#" />
                    }
                    value={tenant}
                    onChange={e => changeTenant(e.target.value)}
                />
                <EuiSpacer />
                <EuiButton color="primary" fill onClick={handleLogin}>
                    Login
                </EuiButton>
                
                <EuiButton color="primary" style={{marginLeft:10}} onClick={()=>changeView({
                        viewName: "SIGNUP"
                    })}>
                        Sign Up
                </EuiButton>
                

                <EuiHorizontalRule size="half" />

                <EuiFlexGroup direction="rowReverse">
                    <EuiFlexItem grow={false}>
                        <EuiLink onClick={()=> window.open("https://docs.tutela.cybersift.io", "_blank")}>
                            Documentation
                        </EuiLink>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiLink onClick={()=> changeView({
                            viewName: "FORGOT-PASSWORD"
                        })}>
                            Forgot Password
                        </EuiLink>
                    </EuiFlexItem>
                </EuiFlexGroup>
                
                
            </EuiPageContentBody>
        </EuiPageContent>
    </Fragment>
}

Login.csComponentName = "login"

export default Login