import React, { useEffect, useState } from 'react';

import {
  EuiBottomBar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiButtonEmpty,
} from '@elastic/eui';
import { Fragment } from 'react';
import moment from 'moment';

const FilterBar =  ({filters = {}, discardClickHandler=null}) => {
  const [currentFilters, changeCurrentFilters] = useState([])

  useEffect(()=>{
    let tempCurrentFilters=[]
    Object.entries(filters).forEach(entry => {
      let k = entry[0]
      let v = entry[1]
      if (v && v !== "false") {
        switch (k) {
          case "resolutionFilter":
            tempCurrentFilters.push({
              name: "Resolution",
              val: v
            })
            break;
          case "exploitFilter":
            tempCurrentFilters.push({
              name: "Exploit Available",
              val: v
            })
            break;
          case "detectionType":
            tempCurrentFilters.push({
              name: "Detection Method",
              val: v
            })
            break;
          case "portFilter":
            tempCurrentFilters.push({
              name: "Port/Package",
              val: v
            })
            break;
          case "addressFilter":
            tempCurrentFilters.push({
              name: "Address",
              val: v
            })
            break;
          case "versionFilter":
            tempCurrentFilters.push({
              name: "Version",
              val: v
            })
            break;
          case "severityFilter":
            tempCurrentFilters.push({
              name: "Severity",
              val: v
            })
            break;
          case "tutelaseverityFilter":
              tempCurrentFilters.push({
                name: "Tutela Severity",
                val: v
              })
              break;
          case "osFilter":
            tempCurrentFilters.push({
              name: "OS",
              val: v
            })
            break;
          case "riskFilter":
            tempCurrentFilters.push({
              name: "Risk",
              val: v
            })
            break;
          case "confidenceFilter":
            tempCurrentFilters.push({
              name: "Confidence",
              val: v
            })
            break;
          case "statusFilter":
            tempCurrentFilters.push({
              name: "Status",
              val: v
            })
            break;
          case "showLatest":
            break;
          case "endDateFilter":
            tempCurrentFilters.push({
              name: "End Date",
              val: moment.unix(v).format("hh:mm DD/MM/YYYY")
            })
            break;
          case "startDateFilter":
            tempCurrentFilters.push({
              name: "Start Date",
              val: moment.unix(v).format("hh:mm DD/MM/YYYY")
            })
            break;
          default:
            tempCurrentFilters.push({
              name: k,
              val: v
            })
            break;
        }
      }
    })

    changeCurrentFilters(tempCurrentFilters)
  },[filters])

    if (!filters){
        return
    }  

    

    return <Fragment>
      {currentFilters.length > 0 ? <EuiBottomBar>
        <EuiFlexGroup justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s">     

              { currentFilters.map(entry => <EuiFlexItem grow={false}>
                    <EuiButton color="ghost" size="s" iconType="search">
                        {entry.name}: {entry.val}
                    </EuiButton>
                </EuiFlexItem>)
              }  

            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup gutterSize="s">
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty color="ghost" size="s" iconType="cross" onClick={discardClickHandler}>
                  Discard Filters
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiBottomBar>: null}
    </Fragment>
    
}

export default FilterBar