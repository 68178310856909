import { EuiButton, EuiTextArea, EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiSpacer, EuiTitle } from '@elastic/eui'
import React, { useState } from 'react'

export default ({ noteText, handleVisibility= ()=>{}, handleUpdateNote= ()=>{} }) => {

    const [pnoteText, changeNoteText]=useState(noteText)

    return <EuiFlyout
        onClose={() => handleVisibility(false)}
        aria-labelledby="flyoutTitle"
    >
        <EuiFlyoutHeader hasBorder>
            <EuiTitle size="m">
                <h2 id="flyoutTitle">Notes</h2>
            </EuiTitle>
        </EuiFlyoutHeader>

        <EuiFlyoutBody>
        <EuiTextArea
            placeholder="Insert Notes"
            value={pnoteText}
            onChange={e => changeNoteText(e.target.value)}
            aria-label="notes-field"
        />      
        
        <EuiSpacer />

        <EuiButton fill color="primary" onClick={()=>handleUpdateNote(pnoteText)}>
            Save
        </EuiButton>

        </EuiFlyoutBody>
    </EuiFlyout>

}