import { BackendMap } from "./Utils"


let apiEndpoint = BackendMap[window.location.hostname] || BackendMap["default"]

// if a localstorage endpoint is set, use that
if (localStorage.getItem("apiEndpoint")){
    apiEndpoint = localStorage.getItem("apiEndpoint")
}
// if (window.location.host.startsWith("localhost")){
//     apiEndpoint = "http://localhost:8080"
// }

const Api =  {
    login: `${apiEndpoint}/userLogin`,
    logout: `${apiEndpoint}/userLogout`,
    checkLogin: `${apiEndpoint}/checkLogin`,
    signUp: `${apiEndpoint}/signUp`,
    renewal: `${apiEndpoint}/renewal`,
    checkAdmin: `${apiEndpoint}/checkAdmin`,
    getUserInfo: `${apiEndpoint}/getUserInfo`,
    generateInviteCode: `${apiEndpoint}/generateInviteCode`,
    generateApiKey: `${apiEndpoint}/generateApiKey`,
    modifyUser: `${apiEndpoint}/userUpdate`,
    retrieveLibraryCVEs: `${apiEndpoint}/retrieveLibraryCVEs`,
    retrieveCVEs_V2: `${apiEndpoint}/retrieveCVEs_v2`,
    retrieveScans: `${apiEndpoint}/retrieveScans`,    
    retrieveScansSummary: `${apiEndpoint}/retrieveScansSummary`,    
    retrieveAgentIPs: `${apiEndpoint}/retrieveAgentIPs`,
    updateNote: `${apiEndpoint}/noteUpdate`,
    updateResolved: `${apiEndpoint}/resolveUpdate`,
    updateLibraryNote: `${apiEndpoint}/libraryNoteUpdate`,
    updateLibraryResolved: `${apiEndpoint}/libraryResolveUpdate`,
    queryCPEs: `${apiEndpoint}/queryCPEs`,
    queryCpeVendors: `${apiEndpoint}/queryCpeVendors`,
    queryCpeProducts: `${apiEndpoint}/queryCpeProducts`,
    overrideCpe: `${apiEndpoint}/overrideCpe`,
    submitAgentIP: `${apiEndpoint}/agentIPSubmission`,
    generateApi: `${apiEndpoint}/userGenerateApiKey`,
    addCloudIntegration: `${apiEndpoint}/submitCloudIntegration`, 
    additionalAgentSettings: `${apiEndpoint}/additionalAgentSettings`,    
    retrieveCloudIntegration: `${apiEndpoint}/retrieveCloudIntegration`,
    retrieveCloudAssets: `${apiEndpoint}/retrieveCloudAssets`,
    deleteCloudIntegration: `${apiEndpoint}/deleteCloudIntegration`,
    refreshCloudIntegration: `${apiEndpoint}/refreshCloudIntegration`,
    deleteAgentIP: `${apiEndpoint}/deleteAgentIP`,        
    getRawScan: `${apiEndpoint}/scans/`,   
    retrieveHostGroups: `${apiEndpoint}/retrieveHostGroups`,   
    retrieveHostGroupCandidates: `${apiEndpoint}/retrieveHostGroupCandidates`, 
    createHostGroup: `${apiEndpoint}/createHostGroup`,   
    deleteHostGroup: `${apiEndpoint}/deleteHostGroup`,   
    modifyHostGroup: `${apiEndpoint}/modifyHostGroup`,    
    deleteScan: `${apiEndpoint}/deleteScan`,    
    retrieveXls: `${apiEndpoint}/retrieveXls`,         
    getUserPlan: `${apiEndpoint}/getUserPlan`,
    getTargetsByAgentIP: `${apiEndpoint}/getTargetsByAgentIP`,
    setTargetsByAgentIP: `${apiEndpoint}/setTargetsByAgentIP`,
    getMyOwnIP: `${apiEndpoint}/getMyOwnIP`,
    retrieveDomains: `${apiEndpoint}/domains`,
    submitDomain: `${apiEndpoint}/domainSubmission`,
    deleteDomain: `${apiEndpoint}/deleteDomain`,
    domainNoteUpdate: `${apiEndpoint}/domainNoteUpdate`,
    domainStatusUpdate: `${apiEndpoint}/domainStatusUpdate`,
    getDomainScreenshot: `${apiEndpoint}/getScreenshot/`,
    getDomainMonitoring: `${apiEndpoint}/getDomainMonitoring`,
    getScraperActivity: `${apiEndpoint}/getScraperActivity`,
    removeDomainMonitoringEntry: `${apiEndpoint}/removeDomainMonitorEntry`,
    removeDomainSuspiciousEntry: `${apiEndpoint}/removeDomainSuspiciousEntry`,
    createNewUser: `${apiEndpoint}/createNewUser`,
    getParkedDomains: `${apiEndpoint}/getParkedDomains`,        
    getSubDomains: `${apiEndpoint}/getSubDomains`,        
    retrieveJSPixel: `${apiEndpoint}/js-pixel`,
    retrieveAssets: `${apiEndpoint}/retrieveAssetsV2/all`,
    retrieveHostAssets: `${apiEndpoint}/retrieveHostAssets`,      
    retrieveNetworkAssets: `${apiEndpoint}/retrieveNetworkAssets`,       
    retrieveSoftwareAssets: `${apiEndpoint}/retrieveAssetsV2/software`,
    retrieveNetworkServiceAssets: `${apiEndpoint}/retrieveAssetsV2/networkService`,    
    deleteAsset: `${apiEndpoint}/deleteAsset`,            
    retrievePaymentStatus: `${apiEndpoint}/retrievePaymentOrderStatus`,            
    retrieveAssetsSummary: `${apiEndpoint}/retrieveAssetsSummary`,                
    reviewNetworkAsset: `${apiEndpoint}/reviewNetworkAsset`,                
    retrieveAssetsByServiceType: `${apiEndpoint}/retrieveAssetsByServiceType`,       
    retrieveWebScannerAlerts: `${apiEndpoint}/getWebScannerAlerts`,   
    deleteWebScannerTarget: `${apiEndpoint}/deleteWebScannerTarget`,   
    submitWebScannerTarget: `${apiEndpoint}/submitWebScannerTarget`,   
    retrieveWebScannerTargets: `${apiEndpoint}/getWebScannerTarget`, 
    resolveWebScannerAlert: `${apiEndpoint}/resolveWebScannerAlert`,   
    submitNoteWebScanAlert: `${apiEndpoint}/submitNoteWebScanAlert`,  
    retrieveComplianceAlerts: `${apiEndpoint}/getComplianceAlerts`,   
    retrieveCloudComplianceResults: `${apiEndpoint}/retrieveCloudComplianceResults`, 
    retrieveCloudInventoryResults: `${apiEndpoint}/retrieveCloudInventoryResults`, 
    resolveComplianceAlert: `${apiEndpoint}/resolveComplianceAlert`,   
    submitNoteComplianceAlert: `${apiEndpoint}/submitNoteComplianceAlert`,  
    generateResetPasswordOTP: `${apiEndpoint}/generateResetPasswordOTP`,     
    handlePasswordReset: `${apiEndpoint}/handlePasswordReset`,           
    addCve: `${apiEndpoint}/addCve`,        
    noteNetworkAsset: `${apiEndpoint}/noteNetworkAsset`,   
    retrieveCveStatusSummary: `${apiEndpoint}/retrieveCveStatus`,
    handleWebFilterBlacklistDelete: `${apiEndpoint}/webFilterBlacklistDelete`,
    handleWebFilterBlacklistSubmission: `${apiEndpoint}/webFilterBlacklistSubmission`,
    retrieveWebFilterBlacklist: `${apiEndpoint}/webFilterBlacklist`,
    handleWebFilterProfileDelete: `${apiEndpoint}/webFilterProfileDelete`,
    handleWebFilterProfileSubmission: `${apiEndpoint}/webFilterProfileSubmission`,
    retrieveWebFilterProfiles: `${apiEndpoint}/webFilterProfiles`,
    handleWebFilterCategoriesSubmission: `${apiEndpoint}/webFilterCategoriesSubmission`,
    retrieveForensicQueries: `${apiEndpoint}/forensicQueries`,
    retrieveForensicResults: `${apiEndpoint}/retrieveForensicResults`,
    deleteForensicQuery: `${apiEndpoint}/deleteForensicQuery`,
    submitForensicQuery: `${apiEndpoint}/saveForensicQueries`,
    retrieveForensicIdsResults: `${apiEndpoint}/retrieveForensicIdsResults`,
    retrieveCsvVulns: `${apiEndpoint}/retrieveCveCsv`,
    handleXevProfileDelete: `${apiEndpoint}/xevProfileDelete`,
    handleXevProfileSubmission: `${apiEndpoint}/xevProfileSubmission`,
    retrieveXevProfiles: `${apiEndpoint}/xevProfiles`,
    handleXevProcessDelete: `${apiEndpoint}/xevProcessDelete`,
    handleXevProcessSubmission: `${apiEndpoint}/xevProcessSubmission`,
	retrieveXevProcesses: `${apiEndpoint}/xevProcess`,
	handleXevPathDelete: `${apiEndpoint}/xevPathDelete`,
    handleXevPathSubmission: `${apiEndpoint}/xevPathSubmission`,
	retrieveXevPaths: `${apiEndpoint}/xevPath`,
	handleXevCategoriesSubmission: `${apiEndpoint}/xevCategoriesSubmission`,
    retrieveXevCategories: `${apiEndpoint}/xevCategories`,
    flashlight: `${apiEndpoint}/muninn`,
    getUserCpes: `${apiEndpoint}/userCPEs`,
    getUserCves: `${apiEndpoint}/userCVEs`,
    addMonitoredCpe: `${apiEndpoint}/addMonitoredCpe`,
    removeMonitoredCpe: `${apiEndpoint}/delMonitoredCpe`,
    retrieveUrlScans: `${apiEndpoint}/retrieveUrlScans`,    
    retrieveFleetAgents: `${apiEndpoint}/retrieveFleetAgents`,
    retrieveFleetProfiles: `${apiEndpoint}/retrieveFleetProfiles`,
    createFleetProfile: `${apiEndpoint}/createFleetProfile`,
    updateFleetProfile: `${apiEndpoint}/updateFleetProfile`,
    removeFleetProfile: `${apiEndpoint}/removeFleetProfile`,
    markSoftwareSafe: `${apiEndpoint}/markSoftwareSafe`,
    unmarkSoftwareSafe: `${apiEndpoint}/unmarkSoftwareSafe`,
    rollCall: `${apiEndpoint}/rollCall`,    
    getNamedAccounts: `${apiEndpoint}/getNamedAccounts`,
    createNamedAccount: `${apiEndpoint}/createNamedAccount`,
    removeNamedAccount: `${apiEndpoint}/removeNamedAccount`,
    retrieveIsoPrinciple: `${apiEndpoint}/iso/retrievePrinciple`,
    retrieveIsoPassingChecks: `${apiEndpoint}/iso/retrievePassingChecks`,
    retrieveIsoFailingChecks: `${apiEndpoint}/iso/retrieveFailingChecks`,
    retrieveEolChecks: `${apiEndpoint}/eol/retrieveEolSoftware`,
    deleteEolCheck: `${apiEndpoint}/eol/deleteEolSoftware`,
    getEntityVulns: `${apiEndpoint}/getEntityVulns`,
    getEntityCompliance: `${apiEndpoint}/getEntityCompliance`,
    getEntityMetadata: `${apiEndpoint}/getEntityMetadata`,
    createCveTag: `${apiEndpoint}/createCveTag`,
    deleteCveTag: `${apiEndpoint}/deleteCveTag`,
    removeByHostname: `${apiEndpoint}/removeByHostname`,
    retrievePortaRegexes: `${apiEndpoint}/porta/regexes`,
    retrievePortaDlp: `${apiEndpoint}/porta/dlp`,
    retrievePortaProfiles: `${apiEndpoint}/porta/profiles`,
    createPortaRegex: `${apiEndpoint}/porta/regexes`,
    createPortaDlp: `${apiEndpoint}/porta/dlp`,
    createPortaProfile: `${apiEndpoint}/porta/profiles`,
    deletePortaRegex: `${apiEndpoint}/porta/deleteRegex`,
    deletePortaDlp: `${apiEndpoint}/porta/deleteDlp`,
    deletePortaProfile: `${apiEndpoint}/porta/deleteProfile`,
    retrievePortaCategories: `${apiEndpoint}/porta/categories`,
}

export default Api