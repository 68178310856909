import React, { useState } from 'react';

import {
  EuiButton,
    EuiCheckbox,
    EuiHorizontalRule,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  htmlIdGenerator,
} from '@elastic/eui';

const WelcomeScreen = () => {

    const showWelcomeScreen = localStorage.getItem('showWelcomeScreen') ? localStorage.getItem('showWelcomeScreen') == "true" ? true : false : true
    const [isChecked, changeIsChecked] = useState(false)
    const [modalVisible, setIsModalVisible] = useState(showWelcomeScreen);

    const handleClose = () => {
        localStorage.setItem('showWelcomeScreen', String(isChecked))
        setIsModalVisible(false)
    }; 



    
    return (
        <div>
            {modalVisible ? <EuiOverlayMask>
            <EuiModal onClose={handleClose} >
            <EuiModalHeader>
                <EuiModalHeaderTitle>CyberSift Vulnerability Tool - Welcome!</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
                <p style={{
                    padding: 10
                }}>
                    <strong><em>
                        Note: By continuing you are agreeing with the <a href="https://www.cybersift.io/tutelaterms">Tutela terms of service</a>.
                    </em></strong>
                </p>

                <p style={{
                    padding: 10
                }}>
                    <strong><em>
                        It's strongly recommended to read the <a href="https://outline.cybersift.io/s/support-wiki/doc/getting-started-rYVOmwKkuy">Getting Started guide</a>.
                    </em></strong>
                </p>

                Follow along with the below video to launch your first scan. 
                <iframe style={{
                    padding:20, 
                    width: "100%",
                    marginLeft: "auto",
                    marginRight: "auto"
                }} title="Video Intro" width="560" height="315" src="https://www.youtube.com/embed/BwdYsZo--9s" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </EuiModalBody>
            <EuiHorizontalRule />
           
            <EuiButton 
                style={{
                    marginLeft: 15,
                    marginRight: 15,
                    marginBottom: 5
                }}
                onClick={handleClose}
            >
                Close
            </EuiButton>

            <EuiModalFooter>
            <EuiCheckbox
                id={htmlIdGenerator()()}
                label="Show this welcome screen next time I login"
                checked={isChecked}
                onChange={(e) => {
                    changeIsChecked(e.target.checked)
                }}
            />
            </EuiModalFooter>
            </EuiModal>
        </EuiOverlayMask> : null}
        
        </div>
    );
};

export default WelcomeScreen