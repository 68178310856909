import { EuiButton } from '@elastic/eui'
import Axios from 'axios'
import React, { useState } from 'react'
import Api from '../../Api'
import LoadingModal from './LoadingModal'
import { Start } from '@mui/icons-material'

const ExportAsCsvServerSide = ({dataView, filters}) =>{

    const [isLoading, changeIsLoading] = useState(false)

    const generateCSV = (csvContent) => {
        

        var exportedFilename = 'export.csv';

        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

        changeIsLoading(false); 
    }


    const exportToCSV = () => {
        changeIsLoading(true)
        switch (dataView) {            
            case "VULN":   
                Axios.post(Api.retrieveCsvVulns, {
                    Scan: filters.scanFilter.toString(),
                    Port: filters.portFilter.toString(),
                    Host: filters.addressFilter.toString(),
                    Severity: filters.severityFilter.toString(),
                    Status: filters.resolutionFilter.toString(),
                    SearchQuery: filters.searchQuery.toString(),
                    HostGroup: filters.hostgroupFilter.toString(),
                    Exploitable: filters.exploitFilter.toString(),
                    DetectionType: filters.detectionType.toString(),
                    StartDateFilter: filters.startDateFilter,
                    EndDateFilter: filters.endDateFilter,
                    ShowLatest: filters.showLatest
                }).then(resp=>{

                    generateCSV(resp.data)
                    
                })             
                break;                                        
            default:
                break;
        }                                   

    }

    return <>
        { isLoading ? <LoadingModal isLoading={isLoading} /> : null }
        <EuiButton
            style={{margin:10}}
            onClick={exportToCSV}
            fill
            color="primary"
        >
            Export To CSV
        </EuiButton>
    </>

}

export default ExportAsCsvServerSide