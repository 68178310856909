import React, { useState, Fragment } from 'react';

import {
  EuiGlobalToastList,
} from '@elastic/eui';


export default () => {
  const [toasts, setToasts] = useState([{
    title: 'Your user account is not enabled',
    color: 'warning',
    iconType: 'user',
    text: (
      <Fragment>
        <p>This is usually due to a licensing or payments issue.</p>
        <p>
          If you have just signed up / renewed please be patient until your payment gets processed. Otherwise please contact support
        </p>
      </Fragment>
    ),
  }]);


  return (
    <div style={{ maxWidth: 320 }}>
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={()=>setToasts([])}
        toastLifeTimeMs={30000}
      />
    </div>
  );
};